import React, { Fragment, useState } from "react";
import { Transition, Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { classNames } from "../../utils/classNames";
import { TextColor } from "../../enums/text/TextColor";
import { TextColorClass } from "../../constants/text/TextColorClass";
import { ActionDropDownProps } from "./interfaces/ActionDropDownProps";

const ActionsDropdown = ({ items, isLoading, label, optionsClassName }: ActionDropDownProps): JSX.Element => {
  const { t } = useTranslation("actions");
  const [selected, setSelected] = useState();

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative">
            <div className="inline-flex shadow-sm rounded-md">
              <div className="relative z-0 inline-flex shadow-sm rounded-md">
                <Listbox.Button
                  type="button"
                  disabled={isLoading}
                  className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 disable:opacity-50"
                >
                  {label || t("actions")}
                </Listbox.Button>
                <Listbox.Button
                  disabled={isLoading}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Listbox.Button>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className={`${optionsClassName} origin-top-left absolute z-10 left-0 mt-2 py-2 w-56 rounded-md shadow-lg overflow-hidden bg-white focus:outline-none`}>
                {items.map((item) => (
                  <Listbox.Option key={item.name} value={item.name}>
                    {({ active }) => (
                      <button
                        onClick={() => item.action()}
                        disabled={item.disabled || isLoading}
                        className={classNames(
                          active ? "bg-gray-100 text-gray-900" : TextColorClass[item?.textColor || TextColor.Gray],
                          "px-4 py-2 text-sm w-full flex flex-row justify-between disable:opacity-50"
                        )}
                      >
                        <span className={TextColorClass[item?.textColor || TextColor.Gray]}>{item.name}</span>
                        {item?.icon && (
                          <span className={classNames(TextColorClass[item?.textColor || TextColor.Gray], "w-5 h-5")}>
                            {item.icon}
                          </span>
                        )}
                      </button>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default ActionsDropdown;
