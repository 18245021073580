import { CustomerPaymentMethod } from "@coasy/csyt-client-library/lib/entities/customer";
import { appHttpService } from "../common/config/auth/useAxios";
import { EntityList } from "../common/interfaces/EntityList/EntityList";
import { ClientCredentials } from "./interfaces/ClientCredentials";
import { CreatingPaymentMethodData } from "./interfaces/CreatingPaymentMethodData";

interface PaymentMethodApiService {
  findAll: (params?: any) => Promise<EntityList<CustomerPaymentMethod, "entities">>;
  isBillingAgreementIdConnected: (id: string) => Promise<boolean>;
  createClientCredentials: () => Promise<ClientCredentials>;
  create: (data: CreatingPaymentMethodData) => Promise<EntityList<CustomerPaymentMethod, "entities">>;
  makeByDefault: (id: string) => Promise<EntityList<CustomerPaymentMethod, "entities">>;
  delete: (id: string) => Promise<void>;
}
const paymentMethodEndpoint = `${process.env.REACT_APP_CUSTOMER_ENDPOINT}/paymentMethods`;

const paymentMethodApiService: PaymentMethodApiService = {
  findAll: (params?: any): Promise<EntityList<CustomerPaymentMethod, "entities">> =>
    appHttpService.get(paymentMethodEndpoint, { params }).then((response) => response.data),
  isBillingAgreementIdConnected: (id: string): Promise<boolean> =>
    appHttpService
      .get(`${paymentMethodEndpoint}/billingAgreement/${id}/isConnected`)
      .then((response) => response.data.isConnected),
  createClientCredentials: (): Promise<ClientCredentials> =>
    appHttpService.post(`${paymentMethodEndpoint}/clientCredentials`).then((response) => response.data),
  create: (data: CreatingPaymentMethodData): Promise<EntityList<CustomerPaymentMethod, "entities">> =>
    appHttpService.post(paymentMethodEndpoint, data).then((response) => response.data),
  makeByDefault: (id: string): Promise<EntityList<CustomerPaymentMethod, "entities">> =>
    appHttpService.put(`${paymentMethodEndpoint}/${id}/makeByDefault`).then((response) => response.data),
  delete: (id: string): Promise<void> => appHttpService.delete(`${paymentMethodEndpoint}/${id}`),
};

export default paymentMethodApiService;
