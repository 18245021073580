import React, { useCallback } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Payment } from "@coasy/csyt-client-library/lib/entities";
import { Invoice } from "@coasy/csyt-client-library/lib/entities/invoice";
import EntityListLayout from "../../../../common/components/EntityList/EntityListLayout";
import EntityCard from "../../../../common/components/EntityList/EntityCard";
import { ListWithSkeletonLayout } from "../../../../common/components/ListWithSkeletonLayout";
import { getConvertedAmount } from "../../../../common/utils/getConvertedAmount";
import { SectionCellType } from "../../../../common/constants/sectionCellType";
import { AppRoutes } from "../../../../common/enums/AppRoutes";
import { PaymentCardItem } from "../../constants/getPaymentCardConfig";
import ViewButton from "../../../../common/components/Button/components/ViewButton";
import RefreshButton from "../../../../common/components/Button/components/RefreshButton";
import { Status } from "../../../../common/enums/Status";

const InvoicePaymentsView = ({ payments, isLoading, invoices }: { payments: Payment[]; isLoading: boolean, invoices?: Invoice[] }): JSX.Element => {
  const { t } = useTranslation("payments");
  const history = useHistory();

  const handleViewPayment = useCallback(
    (invoiceId: string, dueDate: string) => {
      const paymentPath = AppRoutes.PAYMENT_VIEW.replace(":invoiceId?", invoiceId).replace(":dueDate?", dueDate);
      history.push(paymentPath);
    },
    [history]
  );

  const handleRetryPayment = useCallback(
    (invoiceId: string, dueDate: string) => {
      const paymentPath = AppRoutes.PAYMENT_RETRY.replace(":invoiceId?", invoiceId).replace(":dueDate?", dueDate);
      history.push(paymentPath);
    },
    [history]
  );

  const getItemComponent = useCallback(
    (isLoading: boolean, item?: Payment, index?: number) => (
      <EntityCard
        key={`${item?.invoiceId}-${index}`}
        isLoading={isLoading}
        headerData={[
          {
            title: t("due_date"),
            value: item?.dueDate ? format(new Date(item.dueDate), "dd.MM.yyyy") : undefined,
          },
          {
            title: t("total_micro_amount"),
            value: getConvertedAmount(item?.totalMicroAmount),
          },
          {
            title: t("status"),
            value: item?.status,
            type: SectionCellType.Badge,
          },
          {
            title: t("payments:invoice_number"),
            value: invoices?.find((invoice) => invoice.invoiceId === item?.invoiceId)?.number,
            type: SectionCellType.Link,
            linkTo: AppRoutes.INVOICES.replace(":invoiceId?", item?.invoiceId || ""),
          }
        ]}
        itemsData={() => (
          <ListWithSkeletonLayout isLoading={isLoading!} items={item?.items || []} getItemComponent={PaymentCardItem} />
        )}
        navigationElements={
          <div className="flex space-x-3">
            {item?.status === Status.ESCALATE &&
              <RefreshButton handleClick={() => handleRetryPayment(item!.invoiceId, item!.dueDate)} isLoading={isLoading} label={t("payments:pay_now")} />
            }
            <ViewButton handleClick={() => handleViewPayment(item!.invoiceId, item!.dueDate)} isLoading={isLoading} />
          </div>
        }
      />
    ),
    [handleViewPayment, t, invoices, handleRetryPayment]
  );

  return (
    <EntityListLayout title={t("payments")} subTitle={t("payments_subtitle")}>
      <ListWithSkeletonLayout<Payment> isLoading={isLoading} items={payments} getItemComponent={getItemComponent} />
    </EntityListLayout>
  );
};

export default InvoicePaymentsView;
