import React, { useEffect } from "react";
import { CheckCircleIcon, ExclamationIcon, InformationCircleIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import BaseTransition from "../BaseTransition";

export type NotificationType = "success" | "error" | "info";

interface BaseNotificationProps {
  type?: NotificationType;
  isOpen: boolean;
  title: string;
  message?: string;
  onClose: () => void;
}

const renderIcon = (type: NotificationType) => {
  if (type === "success") {
    return <CheckCircleIcon className="h-6 w-6 text-green-400" />;
  }
  if (type === "error") {
    return <ExclamationIcon className="h-6 w-6 text-red-600" />;
  }
  return <InformationCircleIcon className="h-6 w-6 text-blue-600" />;
};

const BaseNotification: React.FC<BaseNotificationProps> = ({ type = "info", isOpen, title, message, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isOpen, onClose]);

  return (
    <div className="fixed inset-x-0 inset-y-0 sm:inset-y-24 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-20">
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        <BaseTransition isOpen={isOpen} type="slide">
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">{renderIcon(type)}</div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">{title}</p>
                  {message && <p className="mt-1 text-sm text-gray-500">{message}</p>}
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={onClose}
                  >
                    <XIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </BaseTransition>
      </div>
    </div>
  );
};

export default BaseNotification;
