import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserSettings } from "../../services/interfaces/UserSettings";
import { RootState } from "../config/redux/store";

export interface UserSettingsState {
  settings?: UserSettings;
  isUserSettingsLoading: boolean;
  isUserSettingsSaving: boolean;
}

const initialState: UserSettingsState = {
  isUserSettingsLoading: false,
  isUserSettingsSaving: false,
};

export const userSettingsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setUserSettings: (state, action: PayloadAction<UserSettings>) => {
      state.settings = action.payload;
    },
    setIsUserSettingsLoading: (state, action: PayloadAction<boolean>) => {
      state.isUserSettingsLoading = action.payload;
    },
    setIsUserSettingsSaving: (state, action: PayloadAction<boolean>) => {
      state.isUserSettingsSaving = action.payload;
    },
  },
});

export const { setUserSettings, setIsUserSettingsLoading, setIsUserSettingsSaving } = userSettingsSlice.actions;

export const selectUserSettings = (state: RootState) => state.userSettings.settings;
export const selectIsUserSettingsLoading = (state: RootState) => state.userSettings.isUserSettingsLoading;
export const selectIsUserSettingsSaving = (state: RootState) => state.userSettings.isUserSettingsSaving;

export default userSettingsSlice.reducer;
