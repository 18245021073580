import React from "react";

import { SectionCellArrayProps } from "./interfaces/SectionCellArrayProps";
import SectionCell from "../SectionCell";

export const SectionCellArray = ({ sectionCellsConfig, className, isLoading }: SectionCellArrayProps) => (
  <dl
    className={
      className || "flex flex-col flex-1 gap-x-14 gap-y-6 py-6 text-sm sm:col-span-3 lg:col-span-2 sm:flex-row sm:gap-y-0"
    }
  >
    {sectionCellsConfig.map((item, index) => (
      <SectionCell key={index} {...item} isLoading={isLoading} />
    ))}
  </dl>
);
