import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { CustomerPaymentMethod } from "@coasy/csyt-client-library/lib/entities/customer";
import Layout from "../../common/components/Layout";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import { useAppSelector } from "../../common/hooks/useAppSelector";
import { thunks, selectors, actions } from "../../common/redux/paymentMethodsSlice";
import paymentMethodApiService from "../../services/PaymentMethodService";
import { BreadcrumbsPath } from "../../common/utils/BreadcrumbsPath";
import { AppRoutes } from "../../common/enums/AppRoutes";
import EntityListLayout from "../../common/components/EntityList/EntityListLayout";
import { ListWithSkeletonLayout } from "../../common/components/ListWithSkeletonLayout";
import EntityCard from "../../common/components/EntityList/EntityCard";
import { PathActionIds } from "../../common/enums/ActionIds";
import { PaymentMethodCreatePanel } from "./PaymentMethodCreatePanel";
import { CreatingPaymentMethodData } from "../../services/interfaces/CreatingPaymentMethodData";
import { Switcher } from "../../common/components/controls/Switcher";
import DeleteDialog from "../../common/components/DeleteDialog";
import { getLabel } from "../../common/utils/getOptions";
import DeleteButton from "../../common/components/Button/components/DeleteButton";
import CreateButton from "../../common/components/Button/components/CreateButton";

interface PaymentMethodsPageParams {
  actionId: string;
}

const PaymentMethodsPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { actionId } = useParams<PaymentMethodsPageParams>();
  const history = useHistory();
  const paymentMethods: CustomerPaymentMethod[] = useAppSelector(selectors.selectAll);
  const isLoading: boolean = useAppSelector(selectors.selectIsLoading);
  const isCreateRequiredDataLoading: boolean = useAppSelector(selectors.selectIsCreateRequiredDataLoading);
  const selectedPaymentMethodId: string = useAppSelector(selectors.selectSelectedEntityId);
  const { t } = useTranslation(["paymentMethods", "actions"]);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const disabledLastPaymentMethodAction = paymentMethods.length < 2;

  const isShowCreatePanel = actionId === PathActionIds.CREATE;

  useEffect(() => {
    dispatch(thunks.fetchTableData(paymentMethodApiService.findAll));
  }, [dispatch]);

  const onCreateSubmit = (data: CreatingPaymentMethodData) => {
    dispatch(
      thunks.createEntity(paymentMethodApiService.create, data, () => {
        history.push(AppRoutes.PAYMENT_METHOD_LIST);
        dispatch(thunks.fetchTableData(paymentMethodApiService.findAll));
      })
    );
  };

  const onCloseCreatePanel = () => {
    history.push(AppRoutes.PAYMENT_METHOD_LIST);
  };

  const onApplyDeleteItem = () => {
    dispatch(
      thunks.removeEntity(paymentMethodApiService.delete, selectedPaymentMethodId, () => {
        dispatch(thunks.fetchTableData(paymentMethodApiService.findAll));
      })
    );
    onCancelDeleteItem();
  };

  const onCancelDeleteItem = () => {
    setDeleteDialogOpen(false);
  };

  const onOpenDeleteItem = useCallback(
    (id: string) => {
      dispatch(actions.setSelectedEntityId(id));
      setDeleteDialogOpen(true);
    },
    [dispatch]
  );

  const handleSelectDefaultMethod = useCallback(
    (id: string, currentValue: boolean) => {
      if (!currentValue) {
        dispatch(thunks.updateEntities(paymentMethodApiService.makeByDefault, id));
      }
    },
    [dispatch]
  );

  const handleOnOpenCreatePanel = () => {
    dispatch(
      thunks.fetchCreateRequiredData(() => {
        history.push(AppRoutes.PAYMENT_METHOD_CREATE);
      })
    );
  };

  const getItemComponent = useCallback(
    (isLoading: boolean, item?: CustomerPaymentMethod, index?: number) => (
      <EntityCard
        key={`${item?.id}-${index}`}
        isLoading={isLoading}
        headerData={[
          {
            title: "ID",
            value: item?.id,
          },
          {
            title: t("payment_method"),
            value: getLabel(item?.paymentMethod || ""),
          },
        ]}
        navigationElements={
          <>
            <Switcher
              label={t("default_payment")}
              disabled={disabledLastPaymentMethodAction}
              value={item?.defaultPayment}
              onChange={(checked: boolean) => {
                if (checked) {
                  handleSelectDefaultMethod(item?.id || "", !!item?.defaultPayment);
                }
              }}
            />
            <DeleteButton
              disabled={disabledLastPaymentMethodAction}
              handleClick={() => onOpenDeleteItem(item?.id || "")}
              isLoading={isLoading}
            />
          </>
        }
      />
    ),
    [disabledLastPaymentMethodAction, handleSelectDefaultMethod, onOpenDeleteItem, t]
  );

  const getBreadcrumbsPath = useCallback(
    (): BreadcrumbsPath | undefined => new BreadcrumbsPath(t("payment_methods"), AppRoutes.PAYMENT_METHOD_LIST),
    [t]
  );

  return (
    <Layout title="Payment Methods | Customer App" breadcrumbsPath={getBreadcrumbsPath()}>
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        onApply={onApplyDeleteItem}
        onCancel={onCancelDeleteItem}
        subject="payment_method"
      />
      <EntityListLayout title={t("payment_methods")}>
        <CreateButton handleClick={handleOnOpenCreatePanel} isLoading={isLoading} inProgress={isCreateRequiredDataLoading} />
        <ListWithSkeletonLayout<CustomerPaymentMethod>
          isLoading={isLoading}
          items={paymentMethods}
          getItemComponent={getItemComponent}
        />
      </EntityListLayout>
      <PaymentMethodCreatePanel isOpen={isShowCreatePanel} onSubmit={onCreateSubmit} onClose={onCloseCreatePanel} />
    </Layout>
  );
};

export default PaymentMethodsPage;
