import React, { PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "./Footer";
import Header from "./Header";
import NavigationMenu from "./NavigationMenu";
import Breadcrumps from "./Breadcrumps";
import { BreadcrumbsPath } from "../../utils/BreadcrumbsPath";

interface LayoutProps {
  title: string;
  breadcrumbsPath?: BreadcrumbsPath;
}

const Layout: React.FC<LayoutProps> = ({ title, breadcrumbsPath, children }: PropsWithChildren<LayoutProps>) => (
  <div className="h-screen flex flex-col bg-mainBackground-light">
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Header />
    <div className="pt-8 overflow-auto flex flex-col flex-grow">
      <div className="flex-grow flex container mx-auto">
        <NavigationMenu />
        <div className="flex-1 relative">
          <Breadcrumps path={breadcrumbsPath} />
          {children}
        </div>
      </div>
      <Footer />
    </div>
  </div>
);

export default Layout;
