import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationType } from "../components/BaseNotification";
import { RootState } from "../config/redux/store";

export interface NotificationsState {
  notificationVisibility: boolean;
  notificationMessage: string;
  notificationType: NotificationType;
}

const initialState: NotificationsState = {
  notificationVisibility: false,
  notificationMessage: "",
  notificationType: "info",
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    closeNotification: (state) => {
      state.notificationVisibility = false;
    },
    openNotification: (state) => {
      state.notificationVisibility = true;
    },
    setNotificationMessage: (state, action: PayloadAction<string>) => {
      state.notificationMessage = action.payload;
    },
    setNotificationType: (state, action: PayloadAction<NotificationType>) => {
      state.notificationType = action.payload;
    },
  },
});

export const { closeNotification, openNotification, setNotificationMessage, setNotificationType } =
  notificationsSlice.actions;

export const selectNotificationVisibility = (state: RootState) => state.notifications.notificationVisibility;
export const selectNotificationMessage = (state: RootState) => state.notifications.notificationMessage;
export const selectNotificationType = (state: RootState) => state.notifications.notificationType;

export default notificationsSlice.reducer;
