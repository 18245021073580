import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../config/redux/store";

export interface UnsavedDataDialogState {
  unsavedDataDialogVisibility: boolean;
}

const initialState: UnsavedDataDialogState = {
  unsavedDataDialogVisibility: false,
};

export const unsavedDataDialogSlice = createSlice({
  name: "unsavedDataDialog",
  initialState,
  reducers: {
    closeUnsavedDataDialog: (state) => {
      state.unsavedDataDialogVisibility = false;
    },
    openUnsavedDataDialog: (state) => {
      state.unsavedDataDialogVisibility = true;
    },
  },
});

export const { closeUnsavedDataDialog, openUnsavedDataDialog } = unsavedDataDialogSlice.actions;

export const selectUnsavedDataDialogVisibility = (state: RootState) => state.unsavedDataDialog.unsavedDataDialogVisibility;

export default unsavedDataDialogSlice.reducer;
