import React from "react";
import { XIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { BaseButtonProps } from "../interfaces/BaseButtonProps";
import Button from "../index";
import { ButtonVariant } from "../constants/buttonVariant";

const CloseButton = ({ handleClick, isLoading, className, screenReaderText }: BaseButtonProps) => {
  const { t } = useTranslation(["actions"]);

  return (
    <Button handleClick={handleClick} variant={ButtonVariant.Icon} isLoading={isLoading} className={className}>
      <>
        <span className="sr-only">{screenReaderText || t("actions:close_panel")}</span>
        <XIcon className="h-6 w-6" aria-hidden="true" />
      </>
    </Button>
  );
};

export default CloseButton;
