import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Button from "../index";
import { BaseButtonProps } from "../interfaces/BaseButtonProps";
import { ButtonVariant } from "../constants/buttonVariant";
import { ButtonTypes } from "../constants/buttonTypes";

const SubmitButton = ({
  handleClick,
  label,
  isLoading,
  inProgress,
  className,
  disabled,
  children,
}: PropsWithChildren<BaseButtonProps>) => {
  const { t } = useTranslation(["actions"]);

  return (
    <Button
      handleClick={handleClick}
      inProgress={inProgress}
      variant={ButtonVariant.Main}
      type={ButtonTypes.Submit}
      label={label}
      isLoading={isLoading}
      className={className}
      disabled={disabled}
    >
      {children || t("actions:save")}
    </Button>
  );
};

export default SubmitButton;
