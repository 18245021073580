import React from "react";
import { useTranslation } from "react-i18next";
import BaseDialog from "../BaseDialog";

interface DeleteDialogProps {
  isOpen: boolean;
  subject: string;
  onApply: () => void;
  onCancel: () => void;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({ isOpen, subject, onApply, onCancel }) => {
  const { t } = useTranslation(["actions", "invitations"]);
  return isOpen ? (
    <BaseDialog
      type="error"
      title={t("delete_confirmation")}
      content={t("are_you_sure_you_want_to_delete", { subject: t("invitations:invitation").toLowerCase() })}
      additionalContent={t("this_action_cannot_be_undone")}
      applyLabel={t("delete")}
      onApply={onApply}
      onCancel={onCancel}
    />
  ) : null;
};

export default DeleteDialog;
