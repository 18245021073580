import React from "react";

interface ImageProps {
  src: string;
  alt?: string;
  size?: string;
  maxWidth?: boolean;
  fit?: "object-contain" | "object-cover" | "object-fill";
  cursorPointer?: boolean;
}

const Image = ({ src, alt, size = "16px", maxWidth, fit = "object-contain", cursorPointer }: ImageProps) => (
  <img
    src={src}
    alt={alt || "Item"}
    className={`${fit} ${cursorPointer ? "cursor-pointer" : ""}`}
    style={{ height: size, ...(maxWidth ? { width: "100%" } : { width: size }) }}
  />
);

export default Image;
