import React from "react";
import map from "lodash/map";
import { Checkbox } from "../../controls/Checkbox";
import { PaymentMethods } from "../../../enums/PymentMethods";
import SepaIcon from "../../../assests/icons/sepa.svg";
import AmexIcon from "../../../assests/icons/amex.svg";
import MastercardIcon from "../../../assests/icons/mastercard.svg";
import PaypalIcon from "../../../assests/icons/paypal.svg";
import VisaIcon from "../../../assests/icons/visa.svg";

interface PaymentMethodProps {
  id: string;
  type: any;
  name: string;
  onChange: (id: string, selected: boolean) => void;
  isSelected: boolean;
}

const Point = () => <div className="w-1 h-1 bg-gray-400 rounded-md" style={{ marginRight: 2 }} />;

const PointGroup = () => (
  <div className="flex mr-3">
    {map(Array(4), (item, index) => (
      <Point key={`item-${index}`} />
    ))}
  </div>
);

export const PaymentMethodSelectedCard = ({ type, onChange, id, isSelected, name }: PaymentMethodProps): JSX.Element => {
  let iconSrc;
  switch (type) {
    case PaymentMethods.SEPA:
      iconSrc = SepaIcon;
      break;
    case PaymentMethods.AMEX:
      iconSrc = AmexIcon;
      break;
    case PaymentMethods.MASTERCARD:
      iconSrc = MastercardIcon;
      break;
    case PaymentMethods.PAYPAL:
      iconSrc = PaypalIcon;
      break;
    case PaymentMethods.VISA:
      iconSrc = VisaIcon;
      break;
  }
  return (
    <div
      className="shadow sm:rounded-lg bg-white py-6 px-4 sm:p-6 flex cursor-pointer"
      onClick={() => onChange(id, !isSelected)}
    >
      <div>{iconSrc ? <img src={iconSrc} alt="payment_method" /> : <div className="w-16 h-11" />}</div>
      <div className="ml-8 flex items-center text-gray-400">
        {type === PaymentMethods.PAYPAL ? (
          <div>{name}</div>
        ) : (
          <>
            <PointGroup /> <PointGroup /> <PointGroup /> <div>{name}</div>
          </>
        )}
      </div>
      <Checkbox
        id="paymentMethod"
        label=""
        value={isSelected}
        onChange={(selected) => onChange(id, selected)}
        className="ml-auto"
      />
    </div>
  );
};
