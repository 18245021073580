import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactData } from "@coasy/csyt-client-library/lib/entities";
import { countries } from "@coasy/csyt-client-library/lib/dictionaries/countries";
import { RootState } from "../config/redux/store";
import { OptionDefinition } from "../interfaces/OptionDefinition";
import { getOptionsByEntities } from "../utils/getOptions";

export interface AddressState {
  billingAddress: ContactData | null;
  countryOptions: OptionDefinition[];
  isBillingAddressLoading: boolean;
  isBillingAddressSaving: boolean;
  deliveryAddress: ContactData | null;
  isDeliveryAddressLoading: boolean;
  isDeliveryAddressSaving: boolean;
}

const initialState: AddressState = {
  billingAddress: null,
  deliveryAddress: null,
  countryOptions: [],
  isBillingAddressLoading: false,
  isBillingAddressSaving: false,
  isDeliveryAddressLoading: false,
  isDeliveryAddressSaving: false,
};

export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setBillingAddress: (state, action: PayloadAction<ContactData>) => {
      state.billingAddress = action.payload;
    },
    setIsBillingAddressLoading: (state, action: PayloadAction<boolean>) => {
      state.isBillingAddressLoading = action.payload;
    },
    setIsBillingAddressSaving: (state, action: PayloadAction<boolean>) => {
      state.isBillingAddressSaving = action.payload;
    },
    setDeliveryAddress: (state, action: PayloadAction<ContactData>) => {
      state.deliveryAddress = action.payload;
    },

    setIsDeliveryAddressLoading: (state, action: PayloadAction<boolean>) => {
      state.isDeliveryAddressLoading = action.payload;
    },

    setIsDeliveryAddressSaving: (state, action: PayloadAction<boolean>) => {
      state.isDeliveryAddressSaving = action.payload;
    },

    setCountryOptions: (state) => {
      state.countryOptions = getOptionsByEntities(countries, { label: "label", valueResolver: "value" });
    },
  },
});

export const {
  setBillingAddress,
  setIsBillingAddressLoading,
  setIsBillingAddressSaving,
  setIsDeliveryAddressSaving,
  setIsDeliveryAddressLoading,
  setDeliveryAddress,
  setCountryOptions,
} = addressSlice.actions;

export const selectBillingAddress = (state: RootState) => state.address.billingAddress;
export const selectIsBillingAddressLoading = (state: RootState) => state.address.isBillingAddressLoading;
export const selectIsBillingAddressSaving = (state: RootState) => state.address.isBillingAddressSaving;
export const selectDeliveryAddress = (state: RootState) => state.address.deliveryAddress;
export const selectCountryOptions = (state: RootState) => state.address.countryOptions;
export const selectIsDeliveryAddressLoading = (state: RootState) => state.address.isDeliveryAddressLoading;
export const selectIsDeliveryAddressSaving = (state: RootState) => state.address.isDeliveryAddressSaving;

export default addressSlice.reducer;
