import React from "react";
import ReactDOM from "react-dom";

interface Props {
  braintreeClientToken?: string;
  onUpdate: (tokenizePayload: any, deviceData: any) => void;
}

interface State {
  paypalCheckout?: any;
  deviceData?: any;
}

export default class PayPalButton extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      paypalCheckout: null,
    };

    this.setupPaypal = this.setupPaypal.bind(this);
  }

  componentDidMount() {
    if (!this.props.braintreeClientToken) return;

    this.setupPaypal();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.braintreeClientToken) return;

    if (!this.props.braintreeClientToken) return;

    if (this.state.paypalCheckout) return;

    this.setupPaypal();
  }

  async setupPaypal() {
    if (!window) return;

    if (this.state.paypalCheckout) return;

    const { braintree } = window;
    if (!braintree) {
      console.log("No Braintree SDK found!");
      return;
    }

    const clientToken = this.props.braintreeClientToken;
    if (!clientToken) {
      console.warn("No Client Token found!");
      return;
    }

    const client = await braintree.client.create({
      authorization: clientToken,
    });

    const dataCollector = await braintree.dataCollector.create({
      client,
      paypal: true,
    });
    const { deviceData } = dataCollector;

    let paypalCheckout = await braintree.paypalCheckout.create({
      client,
    });

    paypalCheckout = await paypalCheckout.loadPayPalSDK({
      vault: true,
      intent: "tokenize",
    });

    const { paypal } = window;
    if (!paypal) console.warn("No PayPal SDK found!");

    this.setState({
      paypalCheckout,
      deviceData,
    });
  }

  createBillingAgreement = () => {
    const { paypalCheckout } = this.state;
    const payment = paypalCheckout.createPayment({
      flow: "vault",
      locale: "de_DE",
    });

    return payment;
  };

  onApprove = async (data: any) => {
    const { paypalCheckout } = this.state;
    const tokenizePayload = await paypalCheckout.tokenizePayment(data);

    this.props.onUpdate(tokenizePayload, this.state.deviceData);
  };

  onCancel = (data: any) => {
    console.log("PayPal payment cancelled", JSON.stringify(data, null, 2));
  };

  onError = (err: any) => {
    console.error("PayPal error", err);
  };

  render() {
    if (!window) return <></>;

    const { paypal } = window;

    if (!paypal || !this.state.paypalCheckout) return <></>;

    const PayPalButton = paypal.Buttons.driver("react", {
      React,
      ReactDOM,
    });

    return (
      <PayPalButton
        fundingSource={paypal.FUNDING.PAYPAL}
        createBillingAgreement={() => this.createBillingAgreement()}
        onApprove={(data: any) => this.onApprove(data)}
        onCancel={(data: any) => this.onCancel(data)}
        onError={(err: any) => this.onError(err)}
      />
    );
  }
}
