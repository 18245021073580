import { TFunction } from "i18next";
import { ControlledFormElementType } from "../../../ControlledFormFields/enums/ControlledFormElementType";
import { ControlledFormElement } from "../../../ControlledFormFields/interfaces/ControlledFormElement";

export const getStripeCreditCardFormFields = (translator: TFunction): ControlledFormElement[] => [
  {
    name: "creditCardOwner",
    label: translator("credit_card_owner"),
    type: ControlledFormElementType.TEXT_FIELD,
    required: true,
  },
];
