import React from "react";
import { FOOTER } from "../../../constants/titles";

const Footer: React.FC = () => (
  <footer className="bg-white shadow text-center sm:text-right text-sm sm:text-base py-4">
    <div className="px-2 sm:px-4 lg:px-8 text-sm">{FOOTER}</div>
  </footer>
);

export default Footer;
