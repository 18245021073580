import { IdSelector } from "@reduxjs/toolkit/dist/entities/models";
import { keys, get } from "lodash";
import { createEntityAdapter, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { ITEMS_PER_PAGE, SortColumn, CellTypes } from "../../components/EntityDataTable";
import { RootState } from "../../config/redux/store";
import { EntityListState } from "../../interfaces/EntityList/EntityListState";
import { sortTableBySortColumn } from "../../utils/sortArrayBySortColumn";
import { openNotification, setNotificationMessage, setNotificationType } from "../notificationsSlice";

export interface TablePrams {
  page?: number;
  limit?: number;
  sortColumn?: SortColumn;
  query?: string;
  filters?: { [key: string]: string };
}

export const createEntitySlice = <T>(
  name = "",
  idSelector: IdSelector<T>,
  initialState?: any,
  reducers?: { [key: string]: (state: EntityListState<T>, action: PayloadAction<T[]>) => void },
  thunks?: (entitySlice: any) => { [key: string]: any },
  selectors?: { [key: string]: (state: EntityListState<T>) => any }
): { actions: any; reducer: any; selectors: any; thunks: any } => {
  const defaultInitialEntityState: EntityListState<T> = {
    currentTableData: [],
    isLoading: false,
    paginationMetadata: {
      itemsPerPage: ITEMS_PER_PAGE[0].value,
      totalElements: 0,
      totalPages: 1,
      currentPage: 1,
    },
    filters: {},
  };

  const entitiesAdapter = createEntityAdapter<T>({
    selectId: idSelector,
  });

  const entitySlice = createSlice({
    name,
    initialState: entitiesAdapter.getInitialState({ ...defaultInitialEntityState, ...initialState }),
    reducers: {
      fetchEntities: () => {},
      setAllEntities: (state, action: PayloadAction<{ data: T[]; isServerSorting?: boolean }>) => {
        const { payload } = action;
        entitiesAdapter.setAll(state as any, payload.data);
        state.paginationMetadata.totalElements = payload.data.length;
        state.isServerSorting = payload.isServerSorting;
      },
      createEntity: (state, action: PayloadAction<T>) => {
        entitiesAdapter.addOne(state as any, action.payload);
      },
      updateEntity: (state, action: PayloadAction<T>) => {
        if (state.selectedEntity) {
          state.selectedEntity = { ...state.selectedEntity, ...action.payload };
        }
        entitiesAdapter.setOne(state as any, action.payload);
      },
      updateEntities: (state, action: PayloadAction<T[]>) => {
        entitiesAdapter.setAll(state as any, action.payload);
      },
      removeEntity: (state, action: PayloadAction<string>) => {
        entitiesAdapter.removeOne(state as any, action.payload);
      },
      setSelectedEntityId: (state, action: PayloadAction<string>) => {
        state.selectedId = action.payload;
      },
      clearSelectedEntityId: (state) => {
        state.selectedId = undefined;
      },
      setSelectedEntity: (state: any, action: PayloadAction<T>) => {
        state.selectedEntity = action.payload;
      },
      clearSelectedEntity: (state: any) => {
        state.selectedEntity = null;
      },
      setCurrentTableData: (state, action: PayloadAction<Draft<T>[]>) => {
        state.currentTableData = action.payload;
      },
      setIsLoading: (state, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
      },
      setInProgress: (state, action: PayloadAction<boolean>) => {
        state.inProgress = action.payload;
      },
      setSortedColumn: (state, action: PayloadAction<SortColumn>) => {
        state.sortedColumn = action.payload;
      },
      setFilters: (state, action: PayloadAction<{ [key: string]: any }>) => {
        state.filters = action.payload;
      },
      setItemsPerPage: (state, action: PayloadAction<number>) => {
        state.paginationMetadata.itemsPerPage = action.payload;
      },
      setCurrentPage: (state, action: PayloadAction<number>) => {
        state.paginationMetadata.currentPage = action.payload;
      },
      ...reducers,
    },
  });

  const setServerTableParams = async (
    entity: string,
    findALl: any,
    dispatch: Dispatch<any>,
    getState: any,
    tablePrams?: TablePrams,
    isServerSorting?: boolean
  ) => {
    try {
      dispatch(entitySlice.actions.setIsLoading(true));

      const { sortedColumn, filters } = getState()[entity];
      const sortValues = get(tablePrams, "sortColumn", sortedColumn);
      const allFilters = get(tablePrams, "filters", filters);

      const urlSearchParams = new URLSearchParams();
      if (sortValues) {
        urlSearchParams.append(
          "sort",
          `${sortValues.id}${
            sortValues?.type === CellTypes.String || sortValues?.type === CellTypes.Badge ? ".keyword" : ""
          }:${sortValues.orderBy}`
        );
      }
      if (allFilters) {
        let filterParamValue = "";

        keys(allFilters).forEach((key, index) => {
          filterParamValue += `${index ? " AND " : ""}${key !== "query" ? `${key} : ` : ""}"${allFilters[key]?.trim()}"`;
        });

        if (filterParamValue?.trim()) {
          urlSearchParams.append("query", filterParamValue);
        }
      }

      const response = await findALl(urlSearchParams);
      dispatch(entitySlice.actions.setAllEntities({ data: response[entity], isServerSorting }));
      dispatch(entitySlice.actions.setSortedColumn(sortValues));
      dispatch(entitySlice.actions.setFilters(allFilters));
    } catch (err) {
      console.error(err);
      dispatch(setNotificationMessage("Error fetching entities"));
      dispatch(setNotificationType("error"));
      dispatch(openNotification());
    } finally {
      dispatch(entitySlice.actions.setIsLoading(false));
    }
  };

  const sliceSelectors = entitiesAdapter.getSelectors<RootState>((state) => get(state, name));

  return {
    actions: entitySlice.actions,
    reducer: entitySlice.reducer,
    selectors: {
      ...sliceSelectors,
      selectCurrentTableData: (state: RootState) => {
        const { isServerSorting } = get(state, name);

        let data: any[] = sliceSelectors.selectAll(state);

        if (!isServerSorting) {
          data = sortTableBySortColumn(data, get(state, name).sortedColumn);
        }

        const { currentPage, itemsPerPage } = get(state, name).paginationMetadata;
        const firstPageIndex = (currentPage - 1) * itemsPerPage;
        const lastPageIndex = firstPageIndex + itemsPerPage;
        return data.slice(firstPageIndex, lastPageIndex);
      },
      selectEntity: (state: RootState) => sliceSelectors.selectById(state, get(state, `${name}.selectedId`, "")),
      selectSelectedEntity: (state: RootState) => get(state, name)?.selectedEntity,
      selectSelectedEntityId: (state: RootState) => get(state, name)?.selectedId,
      selectIsLoading: (state: RootState) => get(state, name)?.isLoading,
      selectInProgress: (state: RootState) => get(state, name)?.inProgress,
      selectSortedColumn: (state: RootState) => get(state, name)?.sortedColumn,
      selectPaginationMetadata: (state: RootState) => get(state, name)?.paginationMetadata,
      selectFilters: (state: RootState) => get(state, name)?.filters,
      ...selectors,
    },
    thunks: {
      fetchTableData:
        (findALl: any, tablePrams?: TablePrams, isServerSorting?: boolean) =>
        async (dispatch: Dispatch<any>, getState: any) => {
          await setServerTableParams(name, findALl, dispatch, getState, tablePrams, isServerSorting);
        },
      removeEntity:
        (deleteById: any, entityId: string, successCallback?: () => void, errorCallback?: (error: any) => void) =>
        async (dispatch: Dispatch<any>, getState: any) => {
          try {
            dispatch(entitySlice.actions.setIsLoading(true));
            await deleteById(entityId);
            dispatch(entitySlice.actions.removeEntity(entityId));
            dispatch(setNotificationMessage("Entity deleted"));
            dispatch(setNotificationType("success"));
            successCallback && successCallback();
          } catch (error) {
            console.error(error);
            errorCallback && errorCallback(error);
            dispatch(setNotificationMessage("Error deleting entity"));
            dispatch(setNotificationType("error"));
          } finally {
            dispatch(entitySlice.actions.setIsLoading(false));
            dispatch(openNotification());
          }
        },
      setCurrentPage: (findALl: any, page: number) => async (dispatch: Dispatch<any>, getState: any) => {
        await setServerTableParams(name, findALl, dispatch, getState, { page });
      },
      setSortedColumn: (findALl: any, sortColumn: SortColumn) => async (dispatch: Dispatch<any>, getState: any) => {
        await setServerTableParams(name, findALl, dispatch, getState, { sortColumn });
      },
      setFilters: (findAll: any, filters: { [key: string]: string }) => async (dispatch: Dispatch<any>, getState: any) => {
        dispatch(entitySlice.actions.setCurrentPage(1));
        await setServerTableParams(name, findAll, dispatch, getState, { filters });
      },
      createEntity:
        (create: any, dtoData: any, successCallback?: (data: any) => void, errorCallback?: (error: any) => void) =>
        async (dispatch: Dispatch<any>, getState: any) => {
          try {
            dispatch(entitySlice.actions.setIsLoading(true));
            const response: any = await create(dtoData);
            dispatch(entitySlice.actions.createEntity(response[name]?.[0]));
            dispatch(setNotificationMessage("Entity created"));
            dispatch(setNotificationType("success"));
            successCallback && successCallback(response[name]?.[0]);
          } catch (error) {
            console.error(error);
            errorCallback && errorCallback(error);
            dispatch(setNotificationMessage("Error creating entity"));
            dispatch(setNotificationType("error"));
          } finally {
            dispatch(entitySlice.actions.setIsLoading(false));
            dispatch(openNotification());
          }
        },
      updateEntities:
        (
          update: any,
          entityId: string,
          dtoData: any,
          successCallback?: (responseData?: any) => void,
          errorCallback?: (error: any) => void
        ) =>
        async (dispatch: Dispatch<any>) => {
          try {
            dispatch(entitySlice.actions.setIsLoading(true));
            const response: any = await update(entityId, dtoData);
            dispatch(entitySlice.actions.updateEntities(response[name]));
            dispatch(setNotificationMessage("Entities updated"));
            dispatch(setNotificationType("success"));
            try {
              successCallback && successCallback(response);
            } catch (e) {
              console.error("SuccessCallback Error", e);
            }
          } catch (error) {
            console.error(error);
            dispatch(setNotificationMessage("Error updating entity"));
            dispatch(setNotificationType("error"));
            try {
              errorCallback && errorCallback(error);
            } catch (e) {
              console.error("ErrorCallback Error", e);
            }
          } finally {
            dispatch(entitySlice.actions.setIsLoading(false));
            dispatch(openNotification());
          }
        },
      setSelectedEntity: (findById: any, entityId: string) => async (dispatch: Dispatch<any>) => {
        try {
          dispatch(entitySlice.actions.setIsLoading(true));
          const response = await findById(entityId);
          dispatch(entitySlice.actions.setSelectedEntity({ ...response[name]?.[0], ...response }));
        } catch (err) {
          console.error(err);
          dispatch(setNotificationMessage("Error fetching entities"));
          dispatch(setNotificationType("error"));
          dispatch(openNotification());
        } finally {
          dispatch(entitySlice.actions.setIsLoading(false));
        }
      },
      ...(thunks ? thunks(entitySlice) : {}),
    },
  };
};
