import React from "react";
import { useTranslation } from "react-i18next";
import { CardElement } from "@stripe/react-stripe-js";
import ControlledFormFields from "../../../ControlledFormFields";
import { getStripeCreditCardFormFields } from "../constants/getStripeСardFormFields";
import { stripeClassNames, stripeStyles } from "../styles/stripeStyles";

const StripeCreditForm = ({ control, errors }: { control: any; errors: any }): JSX.Element => {
  const { t } = useTranslation(["paymentMethods", "actions"]);

  return (
    <>
      <ControlledFormFields control={control} fields={getStripeCreditCardFormFields(t)} errors={errors} />
      <div className={stripeClassNames}>
        <CardElement
          options={{
            style: stripeStyles,
          }}
        />
      </div>
    </>
  );
};

export { StripeCreditForm };
