import { useTranslation } from "react-i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { ContactData } from "@coasy/csyt-client-library/lib/entities";
import { getAddressCardData } from "../constants/getAddressCardData";
import { AddressEditableFields } from "../interfaces/AddressEditableFields";
import { selectCountryOptions } from "../../../common/redux/addressSlice";
import { useAppSelector } from "../../../common/hooks/useAppSelector";
import SubmitButton from "../../../common/components/Button/components/SubmitButton";
import ControlledFormFields from "../../../common/components/ControlledFormFields";

interface AddressCardProps {
  address: ContactData;
  inProgress: boolean;
  title: string;
  onSubmit: (data: AddressEditableFields, addressType: string) => void;
}

const AddressCard = ({ address, onSubmit, title, inProgress }: AddressCardProps) => {
  const { t } = useTranslation(["address", "translation"]);

  const {
    control,
    handleSubmit: RHFHandleSubmit,
    formState: { errors },
  } = useForm<AddressEditableFields>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: address,
  });

  const countryOptions = useAppSelector(selectCountryOptions);

  const handleSubmit = (data: AddressEditableFields) => {
    onSubmit({ ...data }, title);
  };

  return (
    <section aria-labelledby="payment-details-heading">
      <form onSubmit={RHFHandleSubmit(handleSubmit)}>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <>
              <h2 className="text-lg leading-6 font-medium text-gray-900">{title}</h2>
              <p className="mt-1 text-sm text-gray-500">{}</p>
              <div className="mt-6 grid grid-cols-4 grid-cols-sm-1 gap-6">
                <ControlledFormFields
                  className="col-span-4 lg:col-span-2"
                  control={control}
                  fields={getAddressCardData(t, address, countryOptions)}
                  errors={errors}
                />
              </div>
            </>
          </div>
          <div className="px-4 py-3 bg-white text-right sm:px-6">
            <SubmitButton inProgress={inProgress} />
          </div>
        </div>
      </form>
    </section>
  );
};

export default AddressCard;
