import React, { PropsWithChildren } from "react";
import { Dialog } from "@headlessui/react";
import { classNames } from "../../../utils/classNames";
import CloseButton from "../../Button/components/CloseButton";
import { SlidePanelContentHeaderProps } from "../interfaces/SlidePanelContentHeader";

export const SlidePanelContentHeader = ({
  title,
  handleOnClose,
  className = "",
  children,
}: PropsWithChildren<SlidePanelContentHeaderProps>) => (
  <div className={classNames(className, "py-6 px-4 bg-indigo-700 sm:px-6")}>
    <div className="flex items-center justify-between">
      <Dialog.Title className="text-lg font-medium text-white">{title}</Dialog.Title>
      {children}
      <div className="ml-3 h-7 flex items-center">
        <CloseButton handleClick={handleOnClose} />
      </div>
    </div>
  </div>
);
