import React from "react";
import { useTranslation } from "react-i18next";
import EntityFullDetails from "../../../common/components/EntityFullDetails";
import { InvoiceDetails } from "../../../common/interfaces/InvoiceDetails";
import { getEntityDetails, getInvoiceEntityItem, getSummary } from "../constants/getInvoiceCardConfig";
import { ListWithSkeletonLayout } from "../../../common/components/ListWithSkeletonLayout";
import DownloadButton from "../../../common/components/Button/components/DownloadButton";
import ViewButton from "../../../common/components/Button/components/ViewButton";

export const InvoiceView = ({
  invoice,
  isLoading,
  handleViewInvoicePlans,
}: {
  invoice: InvoiceDetails;
  isLoading?: boolean;
  handleViewInvoicePlans: (invoiceId: string) => void;
}): JSX.Element => {
  const { t } = useTranslation(["invoices", "actions"]);

  return (
    <EntityFullDetails
      isLoading={isLoading}
      additionalTitle={t("translation:thank_you")}
      title={`${t("invoices:invoice")} - ${invoice?.number || ""}`}
      actions={
        <div className="flex gap-3">
          <DownloadButton isLoading={isLoading} handleClick={() => window.open(invoice?.pdfUrl)} />{" "}
          <ViewButton
            handleClick={() => handleViewInvoicePlans(invoice!.invoiceId)}
            isLoading={isLoading}
            label={t("invoices:view_payment_plans")}
          />
        </div>
      }
      subtitle={invoice?.title}
      getEntityItems={() => (
        <ListWithSkeletonLayout
          isLoading={isLoading!}
          items={invoice?.invoiceItems}
          getItemComponent={(isLoading, item, index) => getInvoiceEntityItem(t, isLoading, item, index)}
        />
      )}
      getEntityDetails={() => getEntityDetails(t, invoice, isLoading)}
      getSummary={() => getSummary(t, invoice, isLoading)}
      additionalContent={<pre className="text-sm text-gray-900">{invoice?.text}</pre>}
    />
  );
};
