import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Order } from "@coasy/csyt-client-library/lib/entities/order";
import { format } from "date-fns";
import Layout from "../../common/components/Layout";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import { useAppSelector } from "../../common/hooks/useAppSelector";
import { actions, selectors, thunks } from "../../common/redux/ordersSlice";
import orderApiService from "../../services/OrderService";
import { BreadcrumbsPath } from "../../common/utils/BreadcrumbsPath";
import { AppRoutes } from "../../common/enums/AppRoutes";
import EntityListLayout from "../../common/components/EntityList/EntityListLayout";
import { ListWithSkeletonLayout } from "../../common/components/ListWithSkeletonLayout";
import EntityCard from "../../common/components/EntityList/EntityCard";
import { getOrderCardItem, getTotalNetValueDivided, renderMobileNavigation } from "./constants/getOrderCardConfig";
import OrderView from "./components/OrderView";
import ViewButton from "../../common/components/Button/components/ViewButton";

const OrdersPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { actionId, orderId } = useParams<{
    actionId: string;
    orderId: string;
  }>();
  const history = useHistory();
  const orders: Order[] = useAppSelector(selectors.selectAll);
  const isLoading: boolean = useAppSelector(selectors.selectIsLoading);
  const selectedOrder: Order = useAppSelector(selectors.selectSelectedEntity);

  const { t } = useTranslation(["orders", "actions"]);
  const isShowView = actionId;

  useEffect(() => {
    dispatch(thunks.fetchTableData(orderApiService.findAll));
  }, [dispatch]);

  useEffect(() => {
    if (orderId) {
      dispatch(thunks.setSelectedEntity(orderApiService.findById, orderId));
    } else {
      dispatch(actions.clearSelectedEntity());
    }
  }, [orderId, dispatch]);

  const handleViewOrder = useCallback(
    (orderId: string) => {
      history.push(AppRoutes.ORDER_VIEW.replace(":orderId?", orderId));
    },
    [history]
  );

  const handleViewInvoice = useCallback(
    (invoiceId: string) => {
      history.push(AppRoutes.INVOICES.replace(":invoiceId?", invoiceId));
    },
    [history]
  );

  const getItemComponent = useCallback(
    (isLoading: boolean, item?: Order, index?: number) => (
      <EntityCard
        key={`${item?.orderId}-${index}`}
        isLoading={isLoading}
        headerData={[
          {
            title: t("orders:order_id"),
            value: item?.orderId,
          },
          {
            title: t("orders:date"),
            value: item?.timestamp ? format(new Date(item?.timestamp as number), "dd.MM.yyyy") : undefined,
          },
          {
            title: t("orders:total_net_value"),
            value: getTotalNetValueDivided(item?.totalNetMicros),
          },
        ]}
        itemsData={() => (
          <ListWithSkeletonLayout isLoading={isLoading!} items={item?.items} getItemComponent={getOrderCardItem} />
        )}
        navigationElements={
          <>
            <ViewButton
              handleClick={() => handleViewInvoice(item!.invoiceId || "")}
              label={t("orders:view_invoice")}
              isLoading={isLoading}
            />
            <ViewButton
              handleClick={() => handleViewOrder(item!.orderId)}
              label={t("orders:view_order")}
              isLoading={isLoading}
            />
          </>
        }
        mobileNavigationTitle={t("orders:order_options")}
        renderMobileNavigation={(active: boolean) => renderMobileNavigation(item!, active, t)}
      />
    ),
    [handleViewOrder, t, handleViewInvoice]
  );

  const getBreadcrumbsPath = useCallback((): BreadcrumbsPath | undefined => {
    const mainPath = new BreadcrumbsPath(t("orders"), AppRoutes.ORDERS_LIST);
    if (isShowView) {
      const viewPath = new BreadcrumbsPath(orderId || "");
      mainPath.setChildren(viewPath);
      return mainPath;
    }
  }, [isShowView, orderId, t]);

  return (
    <Layout title="Orders | Customer App" breadcrumbsPath={getBreadcrumbsPath()}>
      {isShowView ? (
        <OrderView order={selectedOrder} isLoading={isLoading} handleViewInvoice={handleViewInvoice} />
      ) : (
        <EntityListLayout title={t("orders")} subTitle={t("orders:orders_subtitle")}>
          <ListWithSkeletonLayout<Order> isLoading={isLoading} items={orders} getItemComponent={getItemComponent} />
        </EntityListLayout>
      )}
    </Layout>
  );
};

export default OrdersPage;
