import React from "react";
import { TFunction } from "i18next";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { PaymentItem, Payment } from "@coasy/csyt-client-library/lib/entities/payment";
import { Invoice } from "@coasy/csyt-client-library/lib/entities/invoice";
import EntityCardItem from "../../../common/components/EntityList/EntityCardItem";
import { getConvertedAmount } from "../../../common/utils/getConvertedAmount";
import SectionCell from "../../../common/components/SectionCell";
import { SectionCellArray } from "../../../common/components/SectionCellArray";
import { SectionCellType } from "../../../common/constants/sectionCellType";
import { ContentDirection } from "../../../common/components/SectionCell/constants/ContentDirection";
import { AppRoutes } from "../../../common/enums/AppRoutes";

export const PaymentCardItem = (isLoading?: boolean, item?: PaymentItem, index?: number): JSX.Element => {
  const { t } = useTranslation("payments");
  return (
    <EntityCardItem
      key={`${item?.orderItemId}-${index}`}
      isLoading={isLoading}
      item={{
        id: item?.orderItemId,
        mainSection: (
          <>
            <div className="font-medium text-gray-900 sm:flex sm:justify-between">
              {isLoading ? (
                <>
                  <Skeleton height={20} width={150} />
                  <Skeleton height={20} width={50} />
                </>
              ) : (
                <>
                  <h5>{item?.description}</h5>
                  <p className="mt-2 sm:mt-0">{getConvertedAmount(item?.totalMicroAmount)}</p>
                </>
              )}
            </div>
            {isLoading ? (
              <>
                <Skeleton height={15} className="mt-5" />
                <Skeleton height={15} />
              </>
            ) : (
              <div className="flex flex-col divide-x-0 mt-6 mb-2 sm:flex-row sm:divide-x-2">
                <div className="flex mr-3">
                  <p className="text-gray-900">{t("quantity")}</p>
                  <p className="text-gray-600 pl-2">{item?.quantity}</p>
                </div>
                <div className="flex pl:0 sm:pl-3">
                  <p className="text-gray-900">{t("unit_price")}</p>
                  <p className="text-gray-600 pl-2">{getConvertedAmount(item?.unitMicroAmount)}</p>
                </div>
              </div>
            )}
          </>
        ),
      }}
    />
  );
};

export const getSummary = (t: TFunction, payment?: Payment, isLoading?: boolean): JSX.Element => (
  <SectionCell
    isLoading={isLoading}
    title={t("translation:total")}
    value={getConvertedAmount(payment?.totalMicroAmount)}
    direction={ContentDirection.Horizontal}
    className="flex flex-row justify-between"
  />
);

export const getEntityDetails = (t: TFunction, payment?: Payment, isLoading?: boolean, invoices?: Invoice[]): JSX.Element => (
  <>
    <SectionCellArray
      className="flex-1 grid grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-2 lg:col-span-2 py-6"
      isLoading={isLoading}
      sectionCellsConfig={[
        {
          title: t("payments:description"),
          value: payment?.description,
          type: SectionCellType.HTML,
        },
        {
          title: t("payments:status"),
          value: payment?.status,
          type: SectionCellType.Badge,
        },
      ]}
    />
    <SectionCellArray
      className="flex-1 grid grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-2 lg:col-span-2 py-6"
      isLoading={isLoading}
      sectionCellsConfig={[
        {
          title: t("payments:invoice_number"),
          value: invoices?.find((invoice) => invoice.invoiceId === payment?.invoiceId)?.number,
          type: SectionCellType.Link,
          linkTo: AppRoutes.INVOICES.replace(":invoiceId?", payment?.invoiceId || ""),
        },
        {
          title: t("payments:due_date"),
          value: payment?.dueDate ? format(new Date(payment?.dueDate), "dd.MM.yyyy") : "",
          type: SectionCellType.Text,
        },
      ]}
    />
  </>
);
