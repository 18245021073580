import React, { useEffect } from "react";

import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useAppSelector } from "../../../common/hooks/useAppSelector";
import { PaymentMethodCreateRequiredData, selectors, thunks } from "../../../common/redux/paymentMethodsSlice";
import BaseSlidePanel from "../../../common/components/BaseSlidePanel";
import { PaymentMethodCreateForm } from "../../../common/components/PaymentMethod/PaymentMethodCreateForm";
import { CreatingPaymentMethodData } from "../../../services/interfaces/CreatingPaymentMethodData";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import Spinner from "../../../common/components/Spinner";

interface OfferCreatePanelProps {
  isOpen: boolean;
  onSubmit: (data: CreatingPaymentMethodData) => void;
  onClose: () => void;
}

const PaymentMethodCreatePanel = ({ isOpen, onSubmit, onClose }: OfferCreatePanelProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const isLoading: boolean = useAppSelector(selectors.selectIsLoading);
  const createRequiredData: PaymentMethodCreateRequiredData = useAppSelector(selectors.selectCreateRequiredData);
  const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY as string);

  useEffect(() => {
    if (!createRequiredData && isOpen) {
      dispatch(thunks.fetchCreateRequiredData());
    }
  }, [createRequiredData, dispatch, isOpen]);

  return (
    <BaseSlidePanel isOpen={isOpen} onClose={onClose}>
      {createRequiredData ? (
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ elements, stripe }) => (
              <PaymentMethodCreateForm
                createRequiredData={createRequiredData}
                onSubmit={onSubmit}
                onCancel={onClose}
                isLoading={isLoading}
                elements={elements}
                stripe={stripe}
              />
            )}
          </ElementsConsumer>
        </Elements>
      ) : (
        <div className="flex items-center justify-center h-full">
          <Spinner color="border-white" size="150px" />
        </div>
      )}
    </BaseSlidePanel>
  );
};

export { PaymentMethodCreatePanel };
