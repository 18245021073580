import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { AppRoutes } from "../../../enums/AppRoutes";
import Button from "../../Button";
import { ButtonVariant } from "../../Button/constants/buttonVariant";

const Header = () => {
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const location = useLocation();
  const logoPath = "https://admin.achtsamkeitsakademie.de/assets/images/logos/aa-logo-512.png";

  return (
    <nav className="bg-white shadow z-10">
      <div className="px-2 sm:px-4 lg:px-8">
        <div className="flex h-16">
          <div className="flex flex-1 justify-end">
            <Link
              to={AppRoutes.OVERVIEW}
              className="flex-shrink-0 flex items-center"
              {...(location.pathname === AppRoutes.OVERVIEW)}
            >
              <img className="h-8 w-auto" src={logoPath} alt="Logo" />
            </Link>
          </div>
          <div className="flex flex-1 justify-end items-center">
            <Button
              variant={ButtonVariant.Main}
              handleClick={() => {
                logout({ returnTo: window.location.origin });
              }}
            >
              {t("sign_out")}
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
