import { Transition } from "@headlessui/react";
import React from "react";

type BaseTransitionType = "slide";

interface BaseTransitionProps {
  isOpen: boolean;
  children: React.ReactNode;
  type: BaseTransitionType;
}

const BaseTransition: React.FC<BaseTransitionProps> = ({ isOpen, children, type }) => (
  <Transition
    show={isOpen}
    as={React.Fragment}
    enter="transition duration-100 ease-in-out transform"
    enterFrom="opacity-0 translate-x-full"
    enterTo="opacity-100 translate-x-0"
    leave="transition duration-100 ease-in-out transform"
    leaveFrom="opacity-100 translate-0"
    leaveTo="opacity-0 translate-x-full"
  >
    {children}
  </Transition>
);

export default BaseTransition;
