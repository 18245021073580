import React from "react";
import { BadgeTypeColor } from "./constants/BadgeTypeColor";
import { BadgeProps } from "./interfaces/BadgeProps";

export type BadgeType = "blue" | "yellow" | "green" | "red";

const getColor = (type?: BadgeTypeColor) => {
  switch (type) {
    case BadgeTypeColor.Blue:
      return "bg-blue-100 text-blue-800";
    case BadgeTypeColor.Yellow:
      return "bg-yellow-100 text-yellow-800";
    case BadgeTypeColor.Green:
      return "bg-green-100 text-green-800";
    case BadgeTypeColor.Red:
    default:
      return "bg-red-100 text-red-800";
  }
};

const Badge: React.FC<BadgeProps> = ({ label, color }) => (
  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full border ${getColor(color)}`}>{label}</span>
);

export default Badge;
