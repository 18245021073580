import { Payment } from "@coasy/csyt-client-library/lib/entities/payment";
import { appHttpService } from "../common/config/auth/useAxios";
import { EntityList } from "../common/interfaces/EntityList/EntityList";
import { PaymentRetryData } from "./interfaces/PaymentRetryData";

const paymentEndpoint = `${process.env.REACT_APP_CUSTOMER_ENDPOINT}/payments`;

interface PaymentApiService {
  findAll: (params?: any) => Promise<EntityList<Payment, "payments">>;
  findById: (invoiceId: string) => Promise<EntityList<Payment, "payments">>;
  findByIdAndDate: (invoiceId: string, date: string) => Promise<EntityList<Payment, "payments">>;
  retry: (invoiceId: string, date: string, data: PaymentRetryData) => Promise<EntityList<Payment, "payments">>;
}

const paymentApiService: PaymentApiService = {
  findAll: (params?: any): Promise<EntityList<Payment, "payments">> =>
    appHttpService
      .get<Promise<EntityList<Payment, "payments">>>(paymentEndpoint, { params })
      .then((response) => response.data),
  findById: (invoiceId: string): Promise<EntityList<Payment, "payments">> =>
    appHttpService.get(`${paymentEndpoint}/${invoiceId}`).then((response) => response.data),
  findByIdAndDate: (invoiceId: string, date: string): Promise<EntityList<Payment, "payments">> =>
    appHttpService.get(`${paymentEndpoint}/${invoiceId}/${date}`).then((response) => response.data),
  retry: (invoiceId: string, date: string, data: PaymentRetryData): Promise<EntityList<Payment, "payments">> =>
    appHttpService.post(`${paymentEndpoint}/${invoiceId}/${date}/retry`, data).then((response) => response.data),
};

export default paymentApiService;
