import { Stripe, StripeElements, StripeCardElement } from "@stripe/stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import { PaymentMethodCreateRequiredData } from "../../../../redux/paymentMethodsSlice";
import { throwStripeErrorIfRequired } from "./throwStripeErrorIfRequired";

export const validateStripeCreditCard = async (
  creditCardOwner: string,
  stripe: Stripe | null,
  createRequiredData: PaymentMethodCreateRequiredData,
  elements: StripeElements | null
): Promise<any> => {
  const response = await stripe?.confirmCardSetup(
    createRequiredData.clientCredentials.stripeClientCredentials?.clientSecret || "",
    {
      payment_method: {
        card: elements?.getElement(CardElement) as StripeCardElement,
        billing_details: {
          name: creditCardOwner,
          email: createRequiredData.clientCredentials.email,
        },
      },
    }
  );
  throwStripeErrorIfRequired(response?.error);
};
