import { Invoice } from "@coasy/csyt-client-library/lib/entities/invoice";
import { appHttpService } from "../common/config/auth/useAxios";
import { EntityList } from "../common/interfaces/EntityList/EntityList";
import { UseEntityService } from "./interfaces/UseEntityService";

const invoiceEndpoint = `${process.env.REACT_APP_CUSTOMER_ENDPOINT}/invoices`;

const invoiceApiService: UseEntityService<Invoice, Invoice, "invoices"> = {
  findAll: (params?: any): Promise<EntityList<Invoice, "invoices">> =>
    appHttpService.get<Promise<EntityList<Invoice, "invoices">>>(invoiceEndpoint, { params }).then((response) => response.data),
  findById: (invoiceId: string): Promise<EntityList<Invoice, "invoices">> =>
    appHttpService.get(`${invoiceEndpoint}/${invoiceId}`).then((response) => response.data),
};

export default invoiceApiService;
