import { CustomerPaymentMethod } from "@coasy/csyt-client-library/lib/entities/customer";
import { Dispatch } from "react";
import { ClientCredentials } from "../../services/interfaces/ClientCredentials";
import paymentMethodApiService from "../../services/PaymentMethodService";
import { createEntitySlice } from "./helpers/createEntitySlice";
import { openNotification, setNotificationMessage, setNotificationType } from "./notificationsSlice";

export interface PaymentMethodCreateRequiredData {
  clientCredentials: ClientCredentials;
}

interface InitialState {
  createRequiredData?: PaymentMethodCreateRequiredData;
  isCreateRequiredDataLoading: boolean;
}

const initialState: InitialState = {
  createRequiredData: undefined,
  isCreateRequiredDataLoading: false,
};

export const { actions, reducer, selectors, thunks } = createEntitySlice<CustomerPaymentMethod>(
  "paymentMethods",
  (paymentMethod) => paymentMethod.id || "",
  initialState,
  {
    setCreateRequiredData: (state: any, action: any) => {
      state.createRequiredData = action.payload;
    },
    setIsCreateRequiredDataLoading: (state: any, action: any) => {
      state.isCreateRequiredDataLoading = action.payload;
    },
  },
  (entitySlice: any) => ({
    fetchCreateRequiredData: (successCallBack?: () => void) => async (dispatch: Dispatch<any>) => {
      try {
        dispatch(entitySlice.actions.setIsCreateRequiredDataLoading(true));
        const clientCredentials = await paymentMethodApiService.createClientCredentials();
        dispatch(
          entitySlice.actions.setCreateRequiredData({
            clientCredentials,
          })
        );
        dispatch(entitySlice.actions.setIsCreateRequiredDataLoading(false));
        successCallBack && successCallBack();
      } catch (err) {
        console.error(err);
        dispatch(setNotificationMessage("Error fetching the create required data"));
        dispatch(setNotificationType("error"));
        dispatch(openNotification());
      }
    },
  }),
  {
    selectCreateRequiredData: (state: any) => state.paymentMethods.createRequiredData,
    selectIsCreateRequiredDataLoading: (state: any) => state.paymentMethods.isCreateRequiredDataLoading,
  }
);

export default reducer;
