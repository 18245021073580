import React, { forwardRef } from "react";
import ErrorField from "../../ErrorField";
import { classNames } from "../../../utils/classNames";

export type TextFieldOnChangeWrapper = (value: string, onChange: (value: string) => void) => void;

interface TextFieldProps {
  id: string;
  label: string;
  value: string;
  placeholder?: string;
  required?: boolean;
  type?: "text" | "password";
  showCurrency?: boolean;
  showInputSymbol?: boolean;
  inputSymbol?: string;
  error?: string;
  readonly?: boolean;
  onBlur?: () => void;
  onChange: (...event: any[]) => void;
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      id,
      label,
      value,
      placeholder,
      required,
      type = "text",
      showCurrency,
      showInputSymbol,
      inputSymbol,
      error,
      readonly,
      onBlur,
      onChange,
    }: TextFieldProps,
    ref
  ) => (
    <div>
      <label htmlFor={id} className={`block text-sm font-medium ${error ? "text-red-500" : ""} `}>
        {label}
      </label>

      <div className="w-full flex rounded-md shadow-sm my-1">
        {showCurrency && (
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            €
          </span>
        )}
        <input
          id={id}
          type={type}
          value={value}
          placeholder={placeholder}
          required={required}
          readOnly={readonly}
          onBlur={onBlur}
          onChange={onChange}
          className={classNames(
            "flex-1 block focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none sm:text-sm border-gray-300",
            showInputSymbol && inputSymbol ? "rounded-l-md" : "rounded-md"
          )}
        />
        {showInputSymbol && inputSymbol && (
          <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            {inputSymbol}
          </span>
        )}
      </div>
      {error && <ErrorField value={error} />}
    </div>
  )
);

export default TextField;
