import { Order } from "@coasy/csyt-client-library/lib/entities/order";
import React from "react";
import { useTranslation } from "react-i18next";
import ViewButton from "../../../common/components/Button/components/ViewButton";
import EntityFullDetails from "../../../common/components/EntityFullDetails";
import { ListWithSkeletonLayout } from "../../../common/components/ListWithSkeletonLayout";
import { getEntityDetails, getOrderEntityItem, getSummary } from "../constants/getOrderFullDetailsConfig";

interface OrderViewParams {
  order: Order;
  isLoading: boolean;
  handleViewInvoice: (invoiceId: string) => void;
}

const OrderView: React.FC<OrderViewParams> = ({ order, isLoading, handleViewInvoice }) => {
  const { t } = useTranslation(["orders", "actions"]);

  return (
    <EntityFullDetails
      isLoading={isLoading}
      additionalTitle={t("translation:thank_you")}
      title={`${t("orders:order")} - ${order?.orderId || ""}`}
      actions={<ViewButton handleClick={() => handleViewInvoice(order?.invoiceId || "")} label={t("orders:view_invoice")} />}
      getEntityItems={() => (
        <ListWithSkeletonLayout
          isLoading={isLoading!}
          items={order?.items}
          getItemComponent={(isLoading, item) => getOrderEntityItem(t, isLoading, item)}
        />
      )}
      getEntityDetails={() => getEntityDetails(t, order, isLoading)}
      getSummary={() => getSummary(t, order, isLoading)}
    />
  );
};

export default OrderView;
