import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { SlidePanelContentHeader } from "../../../../../common/components/BaseSlidePanel/components/SlidePanelContentHeader";
import { SlidePanelContentMain } from "../../../../../common/components/BaseSlidePanel/components/SlidePanelContentMain";
import { SlidePanelContentFooter } from "../../../../../common/components/BaseSlidePanel/components/SlidePanelContentFooter";
import { SlidePanelFormContent } from "../../../../../common/components/BaseSlidePanel/components/SlidePanelFormContent";
import Button from "../../../../../common/components/Button";
import SubmitButton from "../../../../../common/components/Button/components/SubmitButton";
import { useAppSelector } from "../../../../../common/hooks/useAppSelector";
import UnsavedDataDialog from "../../../../../common/components/UnsavedDataDialog";
import { closeDialog, openDialog, selectDialogVisibility } from "../../../../../common/redux/dialogsSlice";
import { UNSAVED_DATA_DIALOG_NAME } from "../../../constants/DialogNames";
import { useAppDispatch } from "../../../../../common/hooks/useAppDispatch";
import { PaymentMethodCreateRequiredData } from "../../../../../common/redux/paymentMethodsSlice";
import { CreatingPaymentMethodData } from "../../../../../services/interfaces/CreatingPaymentMethodData";
import { StripeDebitForm } from "../../../../../common/components/PaymentMethod/PaymentMethodCreateForm/components/StripeDebitForm";
import { StripeCreditForm } from "../../../../../common/components/PaymentMethod/PaymentMethodCreateForm/components/StripeCreditForm";
import { PaypalForm } from "../../../../../common/components/PaymentMethod/PaymentMethodCreateForm/components/PaypalForm";
import { submitPaymentMethod } from "../../../../../common/components/PaymentMethod/PaymentMethodCreateForm/utils/submitPaymentMethod";
import { PaymentMethods } from "../../../../../common/components/PaymentMethod/PaymentMethodCreateForm/enums/PaymentMethods";

interface PaymentRetryCreateFormProps {
  onSubmit: (data: CreatingPaymentMethodData) => void;
  onCancel: () => void;
  isLoading: boolean;
  createRequiredData: PaymentMethodCreateRequiredData;
  elements: StripeElements | null;
  stripe: Stripe | null;
  typeId?: string;
}

export const PaymentRetryCreateForm = ({
  onCancel,
  onSubmit,
  isLoading,
  createRequiredData,
  elements,
  stripe,
  typeId,
}: PaymentRetryCreateFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["actions", "payments"]);
  const unsavedDataDialogVisibility: boolean = useAppSelector(selectDialogVisibility(UNSAVED_DATA_DIALOG_NAME));
  const [acceptedAgreement, setAcceptedAgreement] = useState(false);
  const [skippedAcceptedAgreement, setSkippedAcceptedAgreement] = useState(false);
  const [tokenizePayload, setTokenizePayload] = useState<any>();
  const [deviceData, setDeviceData] = useState();
  const [currentTab, setCurrentTab] = useState(PaymentMethods.STRIPE_DIRECT_DEBIT);

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<any>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {},
  });

  useEffect(() => {
    switch (typeId) {
      case "debit":
        setCurrentTab(PaymentMethods.STRIPE_DIRECT_DEBIT);
        break;
      case "credit":
        setCurrentTab(PaymentMethods.STRIPE_CREDIT_CARD);
        break;
      case "paypal":
        setCurrentTab(PaymentMethods.BRAINTREE_PAYPAL);
        break;
    }
  }, [typeId]);

  const handleOpenUnsavedDataDialog = useCallback(() => {
    dispatch(openDialog(UNSAVED_DATA_DIALOG_NAME));
  }, [dispatch]);

  const handleCloseUnsavedDataDialog = useCallback(() => {
    dispatch(closeDialog(UNSAVED_DATA_DIALOG_NAME));
  }, [dispatch]);

  const handleOnCancel = useCallback(
    () => (!isEmpty(dirtyFields) ? handleOpenUnsavedDataDialog() : onCancel()),
    [handleOpenUnsavedDataDialog, onCancel, dirtyFields]
  );

  const onApplyUnsavedDataDialog = useCallback(() => {
    handleCloseUnsavedDataDialog();
    onCancel();
  }, [handleCloseUnsavedDataDialog, onCancel]);

  const onFormSubmit =  async (data: any) => {
    submitPaymentMethod(
      data,
      t,
      tokenizePayload,
      createRequiredData,
      acceptedAgreement,
      currentTab,
      dispatch,
      setSkippedAcceptedAgreement,
      stripe,
      elements,
      onSubmit,
      deviceData,
    );
  };

  const renderContent = () => {
    switch (typeId) {
      case "debit":
        return (
          <StripeDebitForm
            control={control}
            errors={errors}
            acceptedAgreement={acceptedAgreement}
            setAcceptedAgreement={setAcceptedAgreement}
            skippedAcceptedAgreement={skippedAcceptedAgreement}
            setSkippedAcceptedAgreement={setSkippedAcceptedAgreement}
          />
        );
      case "credit":
        return <StripeCreditForm control={control} errors={errors} />;
      default:
        return (
          <PaypalForm
            tokenizePayload={tokenizePayload}
            setTokenizePayload={setTokenizePayload}
            setDeviceData={setDeviceData}
            createRequiredData={createRequiredData}
          />
        );
    }
  };

  return (
    <SlidePanelFormContent onSubmit={handleSubmit(onFormSubmit)}>
      <UnsavedDataDialog
        isOpen={unsavedDataDialogVisibility}
        onCancel={handleCloseUnsavedDataDialog}
        onApply={onApplyUnsavedDataDialog}
      />
      <SlidePanelContentHeader title={t("payments:payment_retry")} handleOnClose={handleOnCancel} />
      <SlidePanelContentMain>{renderContent()}</SlidePanelContentMain>
      <SlidePanelContentFooter className="space-x-3">
        <Button handleClick={handleOnCancel} label={t("actions:cancel")} />
        <SubmitButton inProgress={isLoading}>{t("payments:pay")}</SubmitButton>
      </SlidePanelContentFooter>
    </SlidePanelFormContent>
  );
};
