import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { closeUnsavedDataDialog, selectUnsavedDataDialogVisibility } from "../../redux/unsavedDataDialogSlice";
import UnsavedDataDialog from "../UnsavedDataDialog";

interface BaseSlidePanelProps {
  isOpen?: boolean;
  position?: "left" | "right";
  children: React.ReactNode;
  onClose?: () => void;
  withDialog?: boolean;
}

const BaseSlidePanel: React.FC<BaseSlidePanelProps> = ({
  isOpen = false,
  children,
  onClose,
  position = "right",
  withDialog = true,
}) => {
  const dispatch = useAppDispatch();
  const unsavedDataDialogVisibility = useAppSelector(selectUnsavedDataDialogVisibility);

  const handleCloseUnsavedDataDialog = () => {
    dispatch(closeUnsavedDataDialog());
  };

  const onApplyUnsavedDataDialog = () => {
    handleCloseUnsavedDataDialog();
    onClose && onClose();
  };

  return (
    <>
      {withDialog && (
        <UnsavedDataDialog
          isOpen={unsavedDataDialogVisibility}
          onCancel={handleCloseUnsavedDataDialog}
          onApply={onApplyUnsavedDataDialog}
        />
      )}
      <Transition.Root show={isOpen} as={React.Fragment}>
        <Dialog as="div" static auto-reopen="true" className="fixed inset-0 overflow-hidden z-10" onClose={() => {}}>
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity translate-x-" />

            <div className={`fixed inset-y-0 flex ${position === "right" ? "right-0" : "left-0"}`}>
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom={`${position === "right" ? "translate-x-full" : "-translate-x-1/2"} opacity-0`}
                enterTo=""
                leave="transform transition ease-in-out duration-500"
                leaveFrom=""
                leaveTo={`${position === "right" ? "translate-x-full" : "-translate-x-1/2"} opacity-0`}
              >
                <div className="w-screen max-w-5xl">{children}</div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default BaseSlidePanel;
