import { isEmpty } from "lodash";
import React from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OptionDefinition } from "../../../interfaces/OptionDefinition";
import { requiredRule } from "../../../utils/formRules";
import BaseSelect from ".";

interface ControlledSelectProps<T> {
  multiple?: boolean;
  control: Control<T, object>;
  name: string;
  valueResolver?: string;
  label: string;
  required?: boolean;
  options: OptionDefinition[];
  error?: string;
  disabled?: boolean;
  clearable?: boolean;
}

const ControlledSelect = <T extends FieldValues>({
  multiple = false,
  control,
  name,
  valueResolver,
  label,
  required,
  options,
  error,
  disabled,
  clearable,
}: ControlledSelectProps<T>) => {
  const { t } = useTranslation("validation");

  const viewMultipleData = (values: T[]) => {
    if (!values || isEmpty(values)) {
      return [];
    }
    if (valueResolver) {
      return options.filter((option) => values.some((value) => value[valueResolver] === option.value[valueResolver as any]));
    }
    return options.filter((option: any) => values.includes(option.value));
  };

  const viewData = (value: T) => {
    if (!value) {
      return undefined;
    }
    if (valueResolver) {
      return options.find((option) => option.value[valueResolver as any] === value[valueResolver]);
    }
    return options.find((option: any) => option.value === value);
  };

  return (
    <Controller
      name={name as Path<T>}
      control={control}
      rules={{ ...(required && { ...requiredRule(t("please_fill_out_this_field")) }) }}
      render={({ field }) => (
        <BaseSelect
          id={name}
          label={label}
          initialValue={multiple ? viewMultipleData(field.value) : viewData(field.value)}
          options={options}
          multiple={multiple}
          error={error}
          disabled={disabled}
          clearable={clearable}
          onChange={(option) => {
            if (multiple) {
              return field.onChange((option as any | null)?.map((option: any) => option.value));
            }
            return field.onChange((option as OptionDefinition | null)?.value);
          }}
        />
      )}
    />
  );
};

export default ControlledSelect;
