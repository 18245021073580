import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import BaseNotification from "../common/components/BaseNotification";
import { useAppSelector } from "../common/hooks/useAppSelector";
import {
  selectNotificationVisibility,
  selectNotificationMessage,
  selectNotificationType,
  closeNotification,
} from "../common/redux/notificationsSlice";
import { useAppDispatch } from "../common/hooks/useAppDispatch";
import OverviewPage from "./Overview";
import { AppRoutes } from "../common/enums/AppRoutes";
import OrdersPage from "./Orders";
import InvoicesPage from "./Invoices";
import PaymentMethodsPage from "./PaymentMethods";
import AddressPage from "./Address";
import PaymentsPage from "./Payments";

const App = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const notificationVisibility = useAppSelector(selectNotificationVisibility);
  const notificationMessage = useAppSelector(selectNotificationMessage);
  const notificationType = useAppSelector(selectNotificationType);

  const handleCloseNotification = () => {
    dispatch(closeNotification());
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Customer App</title>
        <script src="https://js.braintreegateway.com/web/3.73.1/js/data-collector.min.js" type="text/javascript" />
        <script src="https://js.braintreegateway.com/web/3.73.1/js/paypal-checkout.min.js" type="text/javascript" />
        <script src="https://js.braintreegateway.com/web/3.73.1/js/client.min.js" type="text/javascript" />
      </Helmet>
      <BaseNotification
        isOpen={notificationVisibility}
        onClose={handleCloseNotification}
        type={notificationType}
        title={notificationMessage}
      />
      <Router>
        <Switch>
          <Route exact path={AppRoutes.OVERVIEW}>
            <OverviewPage />
          </Route>
          <Route exact path={AppRoutes.MY_ADDRESS}>
            <AddressPage />
          </Route>
          <Route exact path={AppRoutes.ORDERS}>
            <OrdersPage />
          </Route>
          <Route exact path={AppRoutes.INVOICES}>
            <InvoicesPage />
          </Route>
          <Route exact path={AppRoutes.SUBSCRIPTIONS}>
            <OverviewPage />
          </Route>
          <Route exact path={AppRoutes.PAYMENT_METHOD}>
            <PaymentMethodsPage />
          </Route>
          <Route exact path={AppRoutes.SETTINGS}>
            <OverviewPage />
          </Route>
          <Route exact path={AppRoutes.SUPPORT}>
            <OverviewPage />
          </Route>
          <Route exact path={AppRoutes.PAYMENTS}>
            <PaymentsPage />
          </Route>
        </Switch>
      </Router>
    </HelmetProvider>
  );
};

export default App;
