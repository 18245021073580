import React from "react";
import { useTranslation } from "react-i18next";
import { IbanElement } from "@stripe/react-stripe-js";
import ControlledFormFields from "../../../ControlledFormFields";
import { getStripeDirectDebitFormFields } from "../constants/getStripeDirectDebitFormFields";
import { Checkbox } from "../../../controls/Checkbox";
import { stripeClassNames, stripeStyles } from "../styles/stripeStyles";
import { tenant } from "../../../../config/tenants";

const StripeDebitForm = ({
  control,
  errors,
  acceptedAgreement,
  setAcceptedAgreement,
  skippedAcceptedAgreement,
  setSkippedAcceptedAgreement,
}: {
  control: any;
  errors: any;
  acceptedAgreement: boolean,
  setAcceptedAgreement: any,
  skippedAcceptedAgreement: boolean,
  setSkippedAcceptedAgreement: any,
}): JSX.Element => {
  const { t } = useTranslation(["paymentMethods", "actions"]);

  return (
    <>
      <ControlledFormFields control={control} fields={getStripeDirectDebitFormFields(t)} errors={errors} />
      <div className={stripeClassNames}>
        <IbanElement
          options={{
            style: stripeStyles,
            supportedCountries: ["SEPA"],
          }}
        />
      </div>
      <Checkbox
        id="direct_debit_checkbox"
        label={`Ich ermächtige ${tenant.company} Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich
              weise ich mein Kreditinstitut an, die von der Peter Beer GmbH auf mein Konto gezogenen Lastschriften
              einzulösen. Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die
              Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten
              Bedingungen.`}
        value={acceptedAgreement}
        className="text-gray-400"
        labelClassName={skippedAcceptedAgreement ? "border border-red-500" : ""}
        onChange={(checked: boolean) => {
          setAcceptedAgreement(checked);
          if (checked && skippedAcceptedAgreement) {
            setSkippedAcceptedAgreement(false);
          }
        }}
      />
    </>
  );
};

export { StripeDebitForm };
