import React, { Fragment, PropsWithChildren, useRef } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { ExclamationIcon, InformationCircleIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";

type DialogType = "warn" | "error" | "info";

const getButtonColor = (type: DialogType): string => {
  let color = "";
  if (type === "info") {
    color = "bg-blue-500 hover:bg-blue-600 focus:ring-blue-400";
  } else if (type === "warn") {
    color = "bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-400";
  } else {
    color = "bg-red-600 hover:bg-red-700 focus:ring-red-500";
  }
  return color;
};

const getIconWrapperColor = (type: DialogType) => {
  let color = "";
  if (type === "info") {
    color = "bg-blue-100";
  } else if (type === "warn") {
    color = "bg-yellow-100 ";
  } else {
    color = "bg-red-100";
  }
  return color;
};

const renderIcon = (type: DialogType) => {
  if (type === "info") {
    return <InformationCircleIcon className="h-6 w-6 text-blue-400" />;
  }
  if (type === "warn") {
    return <ExclamationIcon className="h-6 w-6 text-yellow-500" />;
  }
  return <ExclamationIcon className="h-6 w-6 text-red-600" />;
};

interface BaseDialogProps {
  type?: DialogType;
  title: string;
  content?: string;
  additionalContent?: string;
  applyLabel?: string;
  cancelLabel?: string;
  onApply?: () => void;
  onCancel: () => void;
}

const BaseDialog: React.FC<BaseDialogProps> = ({
  type = "info",
  title,
  content,
  additionalContent,
  applyLabel,
  cancelLabel,
  onApply,
  onCancel,
  children,
}: PropsWithChildren<BaseDialogProps>) => {
  const { t } = useTranslation("actions");
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open
        onClose={onCancel}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div
                  className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${getIconWrapperColor(
                    type
                  )} sm:mx-0 sm:h-10 sm:w-10`}
                >
                  {renderIcon(type)}
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{children || content}</p>
                    {additionalContent && <p className="text-sm text-gray-500">{additionalContent}</p>}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                {onApply && (
                  <button
                    type="button"
                    className={`${getButtonColor(
                      type
                    )} w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                    onClick={onApply}
                  >
                    {applyLabel || t("apply")}
                  </button>
                )}
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onCancel}
                  ref={cancelButtonRef}
                >
                  {cancelLabel || t("cancel")}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BaseDialog;
