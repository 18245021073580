import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import userSettingsReducer from "../../redux/userSettingsSlice";
import notificationsReducer from "../../redux/notificationsSlice";
import dialogsReducer from "../../redux/dialogsSlice";
import addressReducer from "../../redux/addressSlice";
import ordersReducer from "../../redux/ordersSlice";
import invoicesReducer from "../../redux/invoicesSlice";
import paymentMethodsReducer from "../../redux/paymentMethodsSlice";
import unsavedDataDialogReducer from "../../redux/unsavedDataDialogSlice";
import paymentsReducer from "../../redux/paymentsSlice";

const logger = createLogger({
  // ...options
});

export const store = configureStore({
  reducer: {
    dialogs: dialogsReducer,
    unsavedDataDialog: unsavedDataDialogReducer,
    notifications: notificationsReducer,
    userSettings: userSettingsReducer,
    orders: ordersReducer,
    invoices: invoicesReducer,
    address: addressReducer,
    paymentMethods: paymentMethodsReducer,
    payments: paymentsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
