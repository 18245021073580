import React from "react";
import Skeleton from "react-loading-skeleton";
import { EntityItemProps } from "./interfaces/ItemProps";

const EntityItem = ({
  item,
  isLoading,
  isWithImage,
  getInfoPropsSection,
  getInfoSection,
  getInfoPropsRightSection,
}: EntityItemProps) => (
  <div className="py-10 border-b border-gray-200 flex space-x-6">
    {isWithImage &&
      (isLoading ? (
        <Skeleton height={150} width={150} />
      ) : (
        <img
          src={item?.imageUrl}
          alt={item?.imageAlt}
          className="flex-none w-20 h-20 object-center object-contain bg-gray-100 rounded-lg sm:w-40 sm:h-40"
        />
      ))}
    <div className="flex-auto flex flex-col">
      <div>{getInfoSection && getInfoSection()}</div>
      <div className="mt-6 flex-1 flex items-end justify-between">
        <dl className="flex text-sm divide-x divide-gray-200 space-x-4 sm:space-x-6 -ml-4 sm:-ml-4">
          {getInfoPropsSection && getInfoPropsSection()}
        </dl>
        <dl>{getInfoPropsRightSection && getInfoPropsRightSection()}</dl>
      </div>
    </div>
  </div>
);

export default EntityItem;
