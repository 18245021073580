import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { EntityCardProps } from "./interfaces/CardProps";
import { SectionCellArray } from "../SectionCellArray";

const EntityCard = ({
  headerData,
  itemsData,
  navigationElements,
  mobileNavigationTitle,
  mobileNavigationElements,
  renderMobileNavigation,
  isLoading,
}: EntityCardProps) => (
  <div className="bg-white border-t border-b border-gray-200 shadow-sm sm:rounded-lg sm:border">
    <div className="flex items-center p-4 border-b border-gray-200 sm:p-6 sm:gap-x-6">
      <SectionCellArray sectionCellsConfig={headerData} isLoading={isLoading} />

      {mobileNavigationTitle && mobileNavigationElements && (
        <Menu as="div" className="relative flex justify-end lg:hidden">
          <div className="flex items-center">
            <Menu.Button className="-m-2 p-2 flex items-center text-gray-400 hover:text-gray-500">
              <span className="sr-only">{mobileNavigationTitle}</span>
              <DotsVerticalIcon className="w-6 h-6" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-bottom-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>{({ active }) => renderMobileNavigation(active)}</Menu.Item>
              </div>
            </Menu.Items>
            {mobileNavigationElements}
          </Transition>
        </Menu>
      )}

      <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">{navigationElements}</div>
    </div>

    {itemsData && (
      <>
        <h4 className="sr-only">Items</h4>
        <div className="divide-y divide-gray-200">{itemsData()}</div>
      </>
    )}
  </div>
);

export default EntityCard;
