import React from "react";
import { useTranslation } from "react-i18next";
import { RefreshIcon } from "@heroicons/react/solid";
import { BaseButtonProps } from "../interfaces/BaseButtonProps";
import Button from "../index";

const RefreshButton = ({ handleClick, label, isLoading, className }: BaseButtonProps): JSX.Element => {
  const { t } = useTranslation(["actions"]);

  return (
    <Button handleClick={handleClick} label={label} isLoading={isLoading} className={className}>
      <>
        <RefreshIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
        {label || t("actions:reload")}
      </>
    </Button>
  );
};

export default RefreshButton;
