import React, { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Payment } from "@coasy/csyt-client-library/lib/entities";
import { Invoice } from "@coasy/csyt-client-library/lib/entities/invoice";
import Layout from "../../common/components/Layout";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import { useAppSelector } from "../../common/hooks/useAppSelector";
import { selectors, thunks, PaymentMethodCreateRequiredData } from "../../common/redux/paymentsSlice";
import { BreadcrumbsPath } from "../../common/utils/BreadcrumbsPath";
import { AppRoutes } from "../../common/enums/AppRoutes";
import { PathActionIds } from "../../common/enums/ActionIds";
import paymentApiService from "../../services/PaymentService";
import InvoicePaymentsView from "./components/InvoicePaymentsView";
import PaymentView from "./components/PaymentView";
import { PaymentsPanel } from "./components/PaymentsPanel";

const PaymentsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { invoiceId, actionId, dueDate } =
    useParams<{ invoiceId?: string; actionId?: string; dueDate?: string; typeId?: string }>();
  const isInvoicePaymentsView = invoiceId && actionId === PathActionIds.VIEW && !dueDate;
  const isPaymentsView = invoiceId && dueDate && actionId === PathActionIds.VIEW;
  const isShowTable = actionId !== PathActionIds.VIEW;
  const isShowRetry = actionId === PathActionIds.RETRY;
  const { t } = useTranslation(["payments", "actions"]);

  const payments: Payment[] = useAppSelector(selectors.selectAll);
  const requiredData: PaymentMethodCreateRequiredData = useSelector(selectors.selectCreateRequiredData);
  const selectedInvoicePayments: Payment[] = requiredData?.invoicePayments;
  const selectedPayment: Payment = requiredData?.selectedPayment;
  const invoices: Invoice[] = requiredData?.invoices;
  const isLoading: boolean = useAppSelector(selectors.selectIsLoading);
  const isLoadingAdditionalData: boolean = useAppSelector(selectors.selectIsCreateRequiredDataLoading);

  useEffect(() => {
    dispatch(thunks.fetchInvoices());
  }, [dispatch]);

  useEffect(() => {
    if (isInvoicePaymentsView) {
      dispatch(thunks.fetchInvoicePayments(invoiceId));
    } else if (isPaymentsView) {
      dispatch(thunks.fetchSelectedPayment(invoiceId, dueDate));
    } else {
      dispatch(thunks.fetchTableData(paymentApiService.findAll));
    }
  }, [invoiceId, dueDate, isInvoicePaymentsView, isPaymentsView, dispatch]);

  const getBreadcrumbsPath = useCallback((): BreadcrumbsPath | undefined => {
    const tablePath = new BreadcrumbsPath(t("payments:payments"), AppRoutes.PAYMENTS_LIST);
    if (isInvoicePaymentsView) {
      const invoicePath = new BreadcrumbsPath(t("payments:invoices"), AppRoutes.INVOICES_LIST);
      const invoiceNumber = invoices?.find((invoice) => invoice.invoiceId === invoiceId)?.number;
      const invoiceViewPath = new BreadcrumbsPath(invoiceNumber || "", AppRoutes.INVOICES.replace(":invoiceId?", invoiceId));
      const viewPath = new BreadcrumbsPath(t("payments:payments_plans"));
      invoiceViewPath.setChildren(viewPath);
      invoicePath.setChildren(invoiceViewPath);
      return invoicePath;
    }
    if (isPaymentsView) {
      const invoiceNumber = invoices?.find((invoice) => invoice.invoiceId === invoiceId)?.number;
      const viewPath = new BreadcrumbsPath(`${invoiceNumber} / ${dueDate}`);
      tablePath.setChildren(viewPath);
    }
    return tablePath;
  }, [isInvoicePaymentsView, isPaymentsView, invoiceId, dueDate, invoices, t]);

  return (
    <Layout title="Payments | Customer App" breadcrumbsPath={getBreadcrumbsPath()}>
      {isShowTable && <InvoicePaymentsView isLoading={isLoading} payments={payments} invoices={invoices} />}
      {isInvoicePaymentsView && (
        <InvoicePaymentsView isLoading={isLoadingAdditionalData} payments={selectedInvoicePayments} invoices={invoices} />
      )}
      {isPaymentsView && <PaymentView payment={selectedPayment} isLoading={isLoadingAdditionalData} invoices={invoices} />}
      <PaymentsPanel isOpen={isShowRetry} onClose={() => {}} />
    </Layout>
  );
};

export default PaymentsPage;
