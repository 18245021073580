const stripeStyles = {
  base: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "400",
    letterSpacing: "0.00938em",
    fontSize: "1.5rem",
  },
};

const stripeClassNames = "rounded border p-2 pt-3 border-gray-400 my-2 h-12";

export { stripeStyles, stripeClassNames };
