import * as React from "react";
import { SortAscendingIcon } from "@heroicons/react/solid";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { get } from "lodash";
import TablePagination from "./TablePagination";
import { PaginateMetadata } from "../../interfaces/PaginateMetadata";
import Badge from "../Badge";
import { getBadgeColorByStatus } from "../Badge/utils/getBadgeColorByStatus";
import { TableComparators } from "../../utils/sortArrayBySortColumn";
import Image from "../Image";

export type Order = "asc" | "desc";

export interface SortColumn {
  id: string;
  orderBy: Order;
  type?: CellTypes;
  comparator?: TableComparators;
}

export interface Column {
  id: string;
  label: string;
  sortable?: boolean;
  sortAlias?: string;
  width?: number;
}

export enum CellTypes {
  None,
  String,
  Number,
  Date,
  Badge,
  Link,
  Action,
  Img,
}

export interface DataTableConfig<T> extends Column {
  type: CellTypes;
  path?: string;
  pathConverter?: (path: string) => string;
  linkTo?: string;
  resolver?: (columnId: string, data: T) => JSX.Element | any;
  sortByPath?: string;
  comparator?: TableComparators;
}

export interface Row<T> {
  id: string;
  data: T;
}

export interface PaginationProps {
  metadata: PaginateMetadata;
  setCurrentPage: (currentPage: number) => void;
}

interface ItemPerPage {
  value: number;
  label: string;
}

export const ITEMS_PER_PAGE: ItemPerPage[] = [
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
];

export interface EntityDataTableProps<T> {
  tableConfig: DataTableConfig<T>[];
  data: Array<T>;
  trackBy: string | string[];
  // renderCell: (columnId: string, row: Row<T>) => React.ReactNode;
  sortedColumn?: SortColumn;
  setSortedColumn?: (sortedColumn: SortColumn) => void;
  isLoading?: boolean;
  pagination: PaginationProps;
}

export function EntityDataTable<T>({
  tableConfig,
  data,
  trackBy,
  sortedColumn,
  setSortedColumn,
  isLoading = false,
  pagination,
}: EntityDataTableProps<T>) {
  const { t } = useTranslation();

  const onSortColumns = (columnId: string, config: DataTableConfig<any>) => {
    let newSortedColumn: SortColumn;
    if (sortedColumn?.id === columnId) {
      newSortedColumn = { ...sortedColumn };
      newSortedColumn.orderBy = sortedColumn.orderBy === "asc" ? "desc" : "asc";
    } else {
      newSortedColumn = { id: columnId, orderBy: "asc" };
    }

    newSortedColumn.type = config.type || CellTypes.None;
    newSortedColumn.comparator = config?.comparator;
    setSortedColumn?.(newSortedColumn);
  };

  function renderCell<T>(columnId: string, data: T, config: DataTableConfig<T>[]) {
    const cellConfig: DataTableConfig<T> | undefined = config?.find((item) => item.id === columnId);

    if (!cellConfig) return "";

    if (cellConfig?.resolver) return cellConfig.resolver(columnId, data);

    switch (cellConfig.type) {
      case CellTypes.String:
      case CellTypes.Date:
        return get(data, cellConfig?.path || columnId);
      case CellTypes.Number:
        return get(data, cellConfig?.path || columnId);
      case CellTypes.Badge:
        return (
          <Badge
            key={`${columnId}-${get(data, trackBy)}-badge`}
            color={getBadgeColorByStatus(get(data, "status"))}
            label={get(data, "status")}
          />
        );
      case CellTypes.Link:
        return (
          <Link to={cellConfig.linkTo as string} className="text-indigo-600 hover:text-indigo-900">
            {get(data, cellConfig?.path || columnId)}
          </Link>
        );
      case CellTypes.Img:
        const { path, pathConverter } = cellConfig;
        const imgPath = get(data, path || "");
        return <Image src={pathConverter?.(imgPath) || imgPath} size="110px" />;
      default:
        return "";
    }
  }

  const renderTable = (): JSX.Element => (
    <div className="overflow-x-auto">
      <div className="align-middle inline-block min-w-full">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>{tableConfig.map((column) => renderHeaderCell(column))}</tr>
          </thead>
          {data?.length === 0 && !isLoading ? (
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{t("no_data")}</td>
              </tr>
            </tbody>
          ) : isLoading ? (
            <tbody className="bg-white divide-y divide-gray-200">
              {[...Array(3)]?.map((_, index) => renderSkeletonRow(index))}
            </tbody>
          ) : (
            <tbody className="bg-white divide-y divide-gray-200">{data?.map((row) => renderRow(row))}</tbody>
          )}
        </table>
      </div>
    </div>
  );

  const renderHeaderCell = (column: DataTableConfig<T>): JSX.Element => {
    const sortAlias = column.sortByPath || column.sortAlias || column.id;
    const isSortColumn = sortedColumn?.id === sortAlias;
    return (
      <th
        key={column.id}
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        onClick={() => {
          column.sortable && onSortColumns(column?.sortByPath || sortAlias, column);
        }}
      >
        <span className={`${column.sortable ? "cursor-pointer" : ""} whitespace-nowrap`}>
          {column.label}
          {column.sortable && (
            <SortAscendingIcon
              className={`h-4 w-4 ml-1 inline text-gray-300 ${
                isSortColumn
                  ? `text-gray-500 transform transition ${sortedColumn?.orderBy === "asc" ? "" : "rotate-180"}`
                  : "hover:text-gray-500"
              }`}
            />
          )}
        </span>
      </th>
    );
  };

  const renderSkeletonRow = (key: number) => (
    <tr key={key}>
      {tableConfig.map((column, index) => {
        const cellKey = `${column.id}${index}`;
        return renderSkeletonCell(cellKey);
      })}
    </tr>
  );

  const renderSkeletonCell = (key: string) => (
    <td key={key} className="px-6 py-4">
      <Skeleton />
    </td>
  );

  const renderRow = (row: T): JSX.Element => (
    <tr key={getRowKey(row)}>{tableConfig.map((column) => renderTableCell(column, row))}</tr>
  );

  const getRowKey = (row: T) => {
    if (!Array.isArray(trackBy)) {
      return row[trackBy as keyof T] as unknown as string;
    }
    return trackBy.map((item) => row[item as keyof T] as unknown as string).join();
  };

  const renderTableCell = (column: Column, row: T): JSX.Element => {
    const cellKey = `${column.id}${row[trackBy as keyof T]}`;
    return (
      <td key={cellKey} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {renderCell(column.id, row, tableConfig)}
      </td>
    );
  };

  const renderPagination = () => {
    const metadata = pagination?.metadata;
    if (!metadata || isLoading) {
      return null;
    }
    const { totalElements, itemsPerPage, currentPage } = metadata;
    return (
      <TablePagination
        totalElements={totalElements}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onChangePage={pagination?.setCurrentPage}
      />
    );
  };

  return (
    <>
      {renderTable()}
      {renderPagination()}
    </>
  );
}
