import React from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";
import isNumber from "lodash/isNumber";
import { convertCurrencyToNumber } from "../../../utils/convertNumberToCurrency";
import { requiredRule } from "../../../utils/formRules";
import TextField from "./TextField";

export type TextFieldOnChangeWrapper = (value: string, onChange: (value: string) => void) => void;

interface ControlledTextFieldProps<T> {
  control: Control<T>;
  error?: string;
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  type?: "text" | "password";
  showCurrency?: boolean;
  showInputSymbol?: boolean;
  inputSymbol?: string;
  convertToNumber?: boolean;
  readonly?: boolean;
  onChangeWrapper?: TextFieldOnChangeWrapper;
  valueHandler?: (value: any) => any;
}

const ControlledTextField = <T extends FieldValues>({
  control,
  error,
  name,
  label,
  placeholder,
  required,
  type,
  showCurrency,
  showInputSymbol,
  inputSymbol,
  convertToNumber,
  readonly,
  onChangeWrapper,
  valueHandler,
}: ControlledTextFieldProps<T>) => {
  const { t } = useTranslation(["validation", "errors"]);

  return (
    <Controller
      name={name as Path<T>}
      control={control as any}
      rules={{
        ...(required && { ...requiredRule(t("please_fill_out_this_field")) }),
        ...(showCurrency && {
          ...{
            validate: (value) => {
              const errorMessage = t("errors:only_numbers_allowed");
              return isNaN(convertCurrencyToNumber(value)) ? errorMessage : true;
            },
          },
        }),
        ...(convertToNumber && {
          ...{
            validate: (value) => {
              const errorMessage = t("errors:only_numbers_allowed");
              return isNumber(+value) ? true : errorMessage;
            },
          },
        }),
      }}
      render={({ field: { onChange, value, ...rest } }) => (
        <TextField
          id={name}
          {...rest}
          label={label}
          value={valueHandler ? valueHandler(value) : value}
          placeholder={placeholder}
          required={required}
          type={type}
          showCurrency={showCurrency}
          showInputSymbol={showInputSymbol}
          inputSymbol={inputSymbol}
          error={error}
          readonly={readonly}
          onChange={(e) => {
            const { value } = e.target;
            if (onChangeWrapper) {
              onChangeWrapper(value, onChange);
            } else {
              onChange(value);
            }
          }}
        />
      )}
    />
  );
};

export default ControlledTextField;
