import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContactData } from "@coasy/csyt-client-library/lib/entities";
import Skeleton from "react-loading-skeleton";
import Layout from "../../common/components/Layout";
import addressApiService from "../../services/AddressService";
import { useAppSelector } from "../../common/hooks/useAppSelector";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import {
  selectBillingAddress,
  selectDeliveryAddress,
  selectIsBillingAddressLoading,
  selectIsBillingAddressSaving,
  selectIsDeliveryAddressLoading,
  selectIsDeliveryAddressSaving,
  setBillingAddress,
  setCountryOptions,
  setDeliveryAddress,
  setIsBillingAddressLoading,
  setIsBillingAddressSaving,
  setIsDeliveryAddressLoading,
  setIsDeliveryAddressSaving,
} from "../../common/redux/addressSlice";
import AddressCard from "./AddressCard";
import { AddressEditableFields } from "./interfaces/AddressEditableFields";
import { openNotification, setNotificationMessage, setNotificationType } from "../../common/redux/notificationsSlice";
import Button from "../../common/components/Button";
import EntityListLayout from "../../common/components/EntityList/EntityListLayout";

const AddressPage: React.FC = () => {
  const { t } = useTranslation(["address", "actions"]);
  const dispatch = useAppDispatch();
  const billingAddress: ContactData | null = useAppSelector(selectBillingAddress);
  const deliveryAddress: ContactData | null = useAppSelector(selectDeliveryAddress);
  const isBillingAddressLoading: boolean = useAppSelector(selectIsBillingAddressLoading);
  const isDeliveryAddressLoading: boolean = useAppSelector(selectIsDeliveryAddressLoading);
  const isDeliveryAddressSaving: boolean = useAppSelector(selectIsDeliveryAddressSaving);
  const isBillingAddressSaving: boolean = useAppSelector(selectIsBillingAddressSaving);

  useEffect(() => {
    dispatch(setIsBillingAddressLoading(true));
    dispatch(setIsDeliveryAddressLoading(true));
    addressApiService
      .getBillingAddress()
      .then((response) => dispatch(setBillingAddress(response.billingAddress || {})))
      .finally(() => dispatch(setIsBillingAddressLoading(false)));
    addressApiService
      .getDeliveryAddress()
      .then((response) => dispatch(setDeliveryAddress(response.deliveryAddress || {})))
      .finally(() => dispatch(setIsDeliveryAddressLoading(false)));
    dispatch(setCountryOptions());
  }, [dispatch]);

  const onEditSubmit = (data: AddressEditableFields, addressType: string) => {
    if (addressType === "Delivery Address") {
      dispatch(setIsDeliveryAddressSaving(true));
      const dto = { ...deliveryAddress, ...data };

      addressApiService
        .updateDeliveryAddress(dto as any)
        .then((response) => {
          dispatch(setDeliveryAddress(response.deliveryAddress));
          dispatch(setNotificationMessage("Entity updated"));
          dispatch(setNotificationType("success"));
        })
        .catch((err) => {
          dispatch(setNotificationMessage("Error updating entity"));
          dispatch(setNotificationType("error"));
        })
        .finally(() => {
          dispatch(setIsDeliveryAddressSaving(false));
          dispatch(openNotification());
        });
    } else {
      dispatch(setIsBillingAddressSaving(true));
      const dto = { ...billingAddress, ...data };

      addressApiService
        .updateBillingAddress(dto as any)
        .then((response) => {
          dispatch(setDeliveryAddress(response.billingAddress));
          dispatch(setNotificationMessage("Entity updated"));
          dispatch(setNotificationType("success"));
        })
        .catch((err) => {
          dispatch(setNotificationMessage("Error updating entity"));
          dispatch(setNotificationType("error"));
        })
        .finally(() => {
          dispatch(setIsBillingAddressSaving(false));
          dispatch(openNotification());
        });
    }
  };

  return (
    <Layout title="Address | Customer App">
      <EntityListLayout title={t("address:addresses")} subTitle="">
        {!isBillingAddressLoading && billingAddress ? (
          <AddressCard
            address={billingAddress}
            onSubmit={onEditSubmit}
            title={t("billing_address")}
            inProgress={isBillingAddressSaving}
          />
        ) : (
          <section aria-labelledby="payment-details-heading">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white py-6 px-4 sm:p-6">
                <>
                  <h2 className="text-lg leading-6 font-medium text-gray-900">{t("billing_address")}</h2>
                  <Skeleton />
                </>
              </div>
              <div className="px-4 py-3 bg-white text-right sm:px-6">
                <Button isLoading />
              </div>
            </div>
          </section>
        )}

        {!isDeliveryAddressLoading && deliveryAddress ? (
          <AddressCard
            address={deliveryAddress}
            onSubmit={onEditSubmit}
            title={t("delivery_address")}
            inProgress={isDeliveryAddressSaving}
          />
        ) : (
          <section aria-labelledby="payment-details-heading">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white py-6 px-4 sm:p-6">
                <>
                  <h2 className="text-lg leading-6 font-medium text-gray-900">{t("delivery_address")}</h2>
                  <Skeleton />
                </>
              </div>
              <div className="px-4 py-3 bg-white text-right sm:px-6">
                <Button isLoading />
              </div>
            </div>
          </section>
        )}
      </EntityListLayout>
    </Layout>
  );
};

export default AddressPage;
