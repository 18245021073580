import { get } from "lodash";
import React from "react";
import { Control, FieldError, FieldValues } from "react-hook-form";
import { PartialRecord } from "../../interfaces/PartialRecord";
import ControlledSelect from "../controls/BaseSelect/ControlledSelect";
import ControlledTextField from "../controls/TextField/ControlledTextField";
import { ControlledFormElementType } from "./enums/ControlledFormElementType";
import { ControlledFormElement } from "./interfaces/ControlledFormElement";

interface ControlledFormFieldsProps<T> {
  fields: ControlledFormElement[];
  errors: PartialRecord<keyof T, FieldError | any[] | undefined>;
  control: Control<T, object>;
  className?: string;
}

const ControlledFormFields = <T extends FieldValues>({
  control,
  errors,
  fields,
  className,
}: ControlledFormFieldsProps<T>) => {
  const renderElements = () => fields.map((field, idx) => <div key={`field-${idx}`} className={className}>{renderField(field)}</div>);

  const renderField = (field: ControlledFormElement) => {
    const fieldError: FieldError | undefined = get(errors, field.name) as FieldError | undefined;
    switch (field.type) {
      case ControlledFormElementType.SELECT:
        const options = field.options || [];

        return (
          <ControlledSelect
            key={field.name}
            control={control}
            name={field.name}
            valueResolver={field.valueResolver}
            label={field.label}
            options={options}
            multiple={field.multiple}
            error={fieldError?.message}
            required={field.required}
            clearable={field.clearable}
          />
        );
      case ControlledFormElementType.TEXT_FIELD:
      default:
        return (
          <ControlledTextField
            key={field.name}
            control={control}
            name={field.name}
            label={field.label}
            error={fieldError?.message}
            required={field.required}
            showCurrency={field.showCurrency}
            showInputSymbol={field.showInputSymbol}
            inputSymbol={field.inputSymbol}
            convertToNumber={field.convertToNumber}
            readonly={field.readonly}
          />
        );
    }
  };

  return <>{renderElements()}</>;
};

export default ControlledFormFields;
