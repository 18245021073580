import React, { PropsWithChildren } from "react";
import { classNames } from "../../utils/classNames";
import { TabsType } from "./interfaces/TabsProps";
import { TabType } from "./interfaces/TabType";

const Tab = <T extends string>({ name, current, children }: PropsWithChildren<TabType<T>>) =>
  name === current ? <>{children}</> : null;

const Tabs = <T extends string>({ tabs, current, children, onChange }: PropsWithChildren<TabsType<T>>) => (
  <>
    <div className="mb-4">
      <div className="sm:hidden">
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          onChange={(e) => {
            onChange(e.target.value as T);
          }}
          defaultValue={tabs.find((tab) => tab.name === current)?.label}
        >
          {tabs.map((tab) => (
            <option key={tab.label} value={tab.name}>
              {tab.label}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.label}
                className={classNames(
                  tab.name === current
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "cursor-pointer flex-grow py-4 px-1 text-center border-b-2 font-medium text-sm"
                )}
                onClick={() => tab.name !== current && onChange(tab.name)}
              >
                {tab.label}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
    {children}
  </>
);

Tabs.Tab = Tab;

export { Tabs };
