import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import { SkeletonTheme } from "react-loading-skeleton";
import App from "./pages";
import { store } from "./common/config/redux/store";
import InitialLoadingWrapper from "./common/components/InitialLoadingWrapper";

const domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
const audience = process.env.REACT_APP_AUTH0_API_IDENTIFIER || "";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider domain={domain} clientId={clientId} audience={audience} redirectUri={window.location.origin}>
        <SkeletonTheme color="#dfdede">
          <InitialLoadingWrapper>
            <App />
          </InitialLoadingWrapper>
        </SkeletonTheme>
      </Auth0Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
