import React, { useEffect, useCallback } from "react";
import { CustomerPaymentMethod } from "@coasy/csyt-client-library/lib/entities/customer";
import { useHistory, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import BaseSlidePanel from "../../../../common/components/BaseSlidePanel";
import { BasePanelProps } from "../../../../common/components/BaseSlidePanel/interfaces/BasePanelProps";
import Spinner from "../../../../common/components/Spinner";
import { useAppDispatch } from "../../../../common/hooks/useAppDispatch";
import { useAppSelector } from "../../../../common/hooks/useAppSelector";
import { PaymentMethodCreateRequiredData, thunks, selectors } from "../../../../common/redux/paymentMethodsSlice";
import { thunks as paymentsThunks, selectors as selectorsPayments } from "../../../../common/redux/paymentsSlice";
import paymentMethodApiService from "../../../../services/PaymentMethodService";
import { AppRoutes } from "../../../../common/enums/AppRoutes";
import { PaymentRetryForm } from "./components/PaymentRetryForm";
import { PaymentRetryCreateForm } from "./components/PaymentRetryCreateForm";
import { CreatingPaymentMethodData } from "../../../../services/interfaces/CreatingPaymentMethodData";

export const PaymentsPanel = ({ isOpen, onClose }: BasePanelProps<any>): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation("payments");
  const { invoiceId, dueDate, typeId } = useParams<{ invoiceId?: string, dueDate?: string, typeId?: string }>();
  const paymentMethods: CustomerPaymentMethod[] = useAppSelector(selectors.selectAll);
  const isLoading: boolean = useAppSelector(selectors.selectIsLoading);
  const isLoadingPayments: boolean = useAppSelector(selectorsPayments.selectIsLoading);
  const createRequiredData: PaymentMethodCreateRequiredData = useAppSelector(selectors.selectCreateRequiredData);
  const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY as string);

  const onPaySubmit = (id: string) => {
    dispatch(paymentsThunks.paymentRetry(invoiceId, dueDate, id, t, () => {
      dispatch(paymentsThunks.fetchInvoices());
      handleClosePanel();
    }));
  };

  const onCreateMethod = (data: CreatingPaymentMethodData) => {
    dispatch(
      thunks.createEntity(paymentMethodApiService.create, data, (response: CustomerPaymentMethod) => {
        handleClosePanel();
        onPaySubmit(response.id);
      })
    );
  };

  const handleClosePanel = useCallback(() => {
    history.push(AppRoutes.PAYMENTS_LIST);
  }, [history]);

  const handleCloseCreatePanel = useCallback(() => {
    history.push(AppRoutes.PAYMENT_RETRY.replace(":invoiceId?", invoiceId || "").replace(":dueDate?", dueDate || ""));
  }, [history, invoiceId, dueDate]);

  useEffect(() => {
    dispatch(thunks.fetchTableData(paymentMethodApiService.findAll));
  }, [dispatch]);

  useEffect(() => {
    if (!createRequiredData) {
      dispatch(thunks.fetchCreateRequiredData());
    }
  }, [createRequiredData, dispatch]);

  return (
    <BaseSlidePanel withDialog={false} isOpen={isOpen} onClose={onClose}>
      {!typeId ? (
        <PaymentRetryForm
          paymentMethods={paymentMethods}
          onSubmit={onPaySubmit}
          onCancel={handleClosePanel}
          isLoading={isLoadingPayments || isLoading}
        />
      ) : createRequiredData ? (
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ elements, stripe }) => (
              <PaymentRetryCreateForm
                onSubmit={onCreateMethod}
                onCancel={handleCloseCreatePanel}
                isLoading={isLoadingPayments || isLoading}
                createRequiredData={createRequiredData}
                elements={elements}
                stripe={stripe}
                typeId={typeId}
              />
            )}
          </ElementsConsumer>
        </Elements>
      ) : (
        <div className="flex items-center justify-center h-full">
          <Spinner color="border-white" size="150px" />
        </div>
      )}
    </BaseSlidePanel>
  );
};
