import { ContactData } from "@coasy/csyt-client-library/lib/entities";
import { TFunction } from "i18next";
import { OptionDefinition } from "../../../common/interfaces/OptionDefinition";
import { ControlledFormElement } from "../../../common/components/ControlledFormFields/interfaces/ControlledFormElement";
import { ControlledFormElementType } from "../../../common/components/ControlledFormFields/enums/ControlledFormElementType";

export const getAddressCardData = (
  t: TFunction,
  address: ContactData,
  countryOptions: OptionDefinition[]
): ControlledFormElement[] => [
  {
    name: "firstName",
    label: t("first_name"),
    type: ControlledFormElementType.TEXT_FIELD,
    required: true,
  },
  {
    name: "lastName",
    label: t("last_name"),
    type: ControlledFormElementType.TEXT_FIELD,
    required: true,
  },
  {
    name: "email",
    label: t("email"),
    type: ControlledFormElementType.TEXT_FIELD,
    required: true,
  },
  {
    name: "company",
    label: t("company"),
    type: ControlledFormElementType.TEXT_FIELD,
  },
  {
    name: "countryCode",
    label: t("country"),
    type: ControlledFormElementType.SELECT,
    options: countryOptions,
    required: true,
  },
  {
    name: "phone",
    label: t("phone"),
    type: ControlledFormElementType.TEXT_FIELD,
    required: true,
  },
  {
    name: "street",
    label: t("street"),
    type: ControlledFormElementType.TEXT_FIELD,
    required: true,
  },
  {
    name: "city",
    label: t("city"),
    type: ControlledFormElementType.TEXT_FIELD,
    required: true,
  },
  {
    name: "zipCode",
    label: t("zip_code"),
    type: ControlledFormElementType.TEXT_FIELD,
    required: true,
  },
];
