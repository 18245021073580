import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set, get } from "lodash";
import { RootState } from "../config/redux/store";

export interface DialogState {
  isOpen: boolean;
}

export interface DialogsState {
  [key: string]: DialogState;
}

const initialState: DialogsState = {} as DialogsState;

export const dialogsSlice = createSlice({
  name: "dialogs",
  initialState,
  reducers: {
    closeDialog: (state: DialogsState, action: PayloadAction<string>) => {
      set(state, `${action.payload}.isOpen`, false);
    },
    openDialog: (state: DialogsState, action: PayloadAction<string>) => {
      set(state, `${action.payload}.isOpen`, true);
    },
    closeAllDialogs: () => initialState,
  },
});

export const { closeDialog, openDialog, closeAllDialogs } = dialogsSlice.actions;

export const selectDialogVisibility =
  (dialogName: string) =>
  (state: RootState): boolean =>
    get(state.dialogs, `${dialogName}.isOpen`, false) as boolean;

export default dialogsSlice.reducer;
