import React from "react";
import { TFunction } from "i18next";
import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";
import { Order, OrderItem } from "@coasy/csyt-client-library/lib/entities/order";
import { SectionCellType } from "../../../common/constants/sectionCellType";
import { buildS3ImagePath } from "../../../common/utils/buildS3ImagePath";
import { getTotalNetValueDivided } from "./getOrderCardConfig";
import SectionCell from "../../../common/components/SectionCell";
import { getHTMLAddressFromContact } from "../../../common/utils/getAddressFromContact";
import { SectionCellArray } from "../../../common/components/SectionCellArray";
import EntityItem from "../../../common/components/EntityFullDetails/EntityItem";
import { ContentDirection } from "../../../common/components/SectionCell/constants/ContentDirection";

export const getOrderEntityItem = (t: TFunction, isLoading?: boolean, item?: OrderItem): JSX.Element => {
  const paymentPlan = !isLoading
    ? item?.offer?.paymentPlans?.find((plan) => plan.id === item?.selectedPaymentPlan)
    : undefined;

  return (
    <EntityItem
      isLoading={isLoading}
      isWithImage
      key={item?.orderItemId}
      item={{
        id: item?.orderItemId,
        imageUrl: buildS3ImagePath(item?.offer.image?.s3Key || ""),
        imageAlt: item?.offer.image?.fileName || "",
      }}
      getInfoSection={() => (
        <>
          {isLoading ? <Skeleton height={15} /> : <h4 className="font-medium text-gray-900 mb-5">{item?.offer.title}</h4>}
          <div className="flex gap-7">
            <SectionCell
              isLoading={isLoading}
              title={t("orders:payment_plan")}
              value={paymentPlan?.description}
              type={SectionCellType.Text}
            />
            <SectionCell
              isLoading={isLoading}
              title={t("translation:status")}
              value={item?.status}
              type={SectionCellType.Badge}
            />
          </div>
        </>
      )}
      getInfoPropsSection={() => (
        <>
          <SectionCell
            isLoading={isLoading}
            title={t("translation:quantity")}
            value={item?.count || 1}
            direction={ContentDirection.Horizontal}
          />
        </>
      )}
    />
  );
};

export const getEntityDetails = (t: TFunction, order: Order, isLoading?: boolean): JSX.Element => (
  <>
    <SectionCellArray
      className="flex-1 grid grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-2 lg:col-span-2 py-6"
      isLoading={isLoading}
      sectionCellsConfig={[
        {
          title: t("orders:delivery_address"),
          value: getHTMLAddressFromContact(order?.deliveryAddress),
          type: SectionCellType.HTML,
        },
        {
          title: t("orders:billing_address"),
          value: getHTMLAddressFromContact(order?.billingAddress),
          type: SectionCellType.HTML,
        },
      ]}
    />
    <SectionCellArray
      className="flex-1 grid grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-2 lg:col-span-2 py-6"
      isLoading={isLoading}
      sectionCellsConfig={[
        {
          title: t("orders:payment_method"),
          value: `${t(`orders:${order?.paymentMethod}`)}`,
        },
        {
          title: t("orders:date"),
          value: order?.timestamp ? format(new Date(order.timestamp as number), "dd.MM.yyyy") : undefined,
        },
      ]}
    />
    <SectionCellArray
      className="flex-1 grid grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-2 lg:col-span-2 py-6"
      isLoading={isLoading}
      sectionCellsConfig={[
        {
          title: t("orders:order_status"),
          value: order?.status || "",
          type: SectionCellType.Badge,
        },
      ]}
    />
  </>
);

export const getSummary = (t: TFunction, order?: Order, isLoading?: boolean): JSX.Element => (
  <SectionCell
    isLoading={isLoading}
    title={t("translation:total")}
    value={getTotalNetValueDivided(order?.totalNetMicros)}
    direction={ContentDirection.Horizontal}
    className="flex flex-row justify-between"
  />
);
