import React, { useState } from "react";

import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Tabs } from "../../Tabs";
import { getPaymentMethodTabs } from "./constants/paymentMethodTabs";
import { PaymentMethods } from "./enums/PaymentMethods";
import { PaymentMethodCreateFormProps } from "./interfaces/PaymentMethodCreateFormProps";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import Button from "../../Button";
import SubmitButton from "../../Button/components/SubmitButton";
import { StripeDebitForm } from "./components/StripeDebitForm";
import { StripeCreditForm } from "./components/StripeCreditForm";
import { PaypalForm } from "./components/PaypalForm";
import { submitPaymentMethod } from "./utils/submitPaymentMethod";

const PaymentMethodCreateForm = ({
  createRequiredData,
  isLoading,
  elements,
  stripe,
  onSubmit,
  onCancel,
}: PaymentMethodCreateFormProps): JSX.Element => {
  const { t } = useTranslation(["paymentMethods", "actions"]);
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = useState(PaymentMethods.STRIPE_DIRECT_DEBIT);
  const [tokenizePayload, setTokenizePayload] = useState<any>();
  const [deviceData, setDeviceData] = useState();
  const [acceptedAgreement, setAcceptedAgreement] = useState(false);
  const [skippedAcceptedAgreement, setSkippedAcceptedAgreement] = useState(false);

  const {
    control,
    handleSubmit: RHFHandleSubmit,
    formState: { errors },
  } = useForm<any>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const handleOnSubmit = async (data: any) => {
    submitPaymentMethod(
      data,
      t,
      tokenizePayload,
      createRequiredData,
      acceptedAgreement,
      currentTab,
      dispatch,
      setSkippedAcceptedAgreement,
      stripe,
      elements,
      onSubmit,
      deviceData,
    );
  };

  const renderContent = () => (
    <Tabs tabs={getPaymentMethodTabs(t)} current={currentTab} onChange={(name: PaymentMethods) => setCurrentTab(name)}>
      <Tabs.Tab name={PaymentMethods.STRIPE_DIRECT_DEBIT} current={currentTab}>
        <StripeDebitForm
          control={control}
          errors={errors}
          acceptedAgreement={acceptedAgreement}
          setAcceptedAgreement={setAcceptedAgreement}
          skippedAcceptedAgreement={skippedAcceptedAgreement}
          setSkippedAcceptedAgreement={setSkippedAcceptedAgreement}
        />
      </Tabs.Tab>
      <Tabs.Tab name={PaymentMethods.STRIPE_CREDIT_CARD} current={currentTab}>
        <StripeCreditForm control={control} errors={errors} />
      </Tabs.Tab>
      <Tabs.Tab name={PaymentMethods.BRAINTREE_PAYPAL} current={currentTab}>
        <PaypalForm
          tokenizePayload={tokenizePayload}
          setTokenizePayload={setTokenizePayload}
          setDeviceData={setDeviceData}
          createRequiredData={createRequiredData}
        />
      </Tabs.Tab>
    </Tabs>
  );

  return (
    <form
      onSubmit={RHFHandleSubmit(handleOnSubmit)}
      className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
    >
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="py-6 px-4 bg-indigo-700 sm:px-6">
          <div className="flex items-center justify-between">
            <Dialog.Title className="text-lg font-medium text-white">{t("create_payment_method")}</Dialog.Title>
            <div className="ml-3 h-7 flex items-center">
              <button
                type="button"
                className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={onCancel}
              >
                <span className="sr-only">{t("actions:close_panel")}</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-between">
          <div className="px-4 space-y-6 pt-6 pb-5 sm:px-6">{renderContent()}</div>
        </div>
      </div>
      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
        <Button handleClick={onCancel}>{t("actions:cancel")}</Button>
        <SubmitButton inProgress={isLoading} className="ml-4 inline-flex" />
      </div>
    </form>
  );
};

export { PaymentMethodCreateForm };
