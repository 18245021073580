import React, { PropsWithChildren } from "react";
import { EntityListLayoutProps } from "./interfaces/EntityListLayoutProps";

const EntityListLayout = ({ title, subTitle, children }: PropsWithChildren<EntityListLayoutProps>) => (
  <div className="mx-8 mb-8">
    <div className="py-5">
      <div className="max-w-7xl mx-auto sm:px-2 lg:px-8">
        <div className="max-w-2xl mx-auto px-4 lg:max-w-4xl lg:px-0">
          {!!title && <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">{title}</h2>}
          {!!subTitle && <p className="mt-2 text-sm text-gray-500">{subTitle}</p>}
        </div>
      </div>

      <div className="mt-16">
        {!!title && <h3 className="sr-only">{title}</h3>}
        <div className="max-w-7xl mx-auto sm:px-2 lg:px-8">
          <div className="max-w-2xl mx-auto space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">{children}</div>
        </div>
      </div>
    </div>
  </div>
);

export default EntityListLayout;
