import React, { PropsWithChildren } from "react";
import { TrashIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import Button from "../index";
import { BaseButtonProps } from "../interfaces/BaseButtonProps";

const DeleteButton = ({
  handleClick,
  label,
  isLoading,
  inProgress,
  className,
  disabled,
}: PropsWithChildren<BaseButtonProps>) => {
  const { t } = useTranslation(["actions"]);

  return (
    <Button
      handleClick={handleClick}
      inProgress={inProgress}
      label={label}
      isLoading={isLoading}
      className={className}
      disabled={disabled}
    >
      <>
        <TrashIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
        {label || t("actions:delete")}
      </>
    </Button>
  );
};

export default DeleteButton;
