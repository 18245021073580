import React from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import Skeleton from "react-loading-skeleton";
import { LongDash } from "../../constants/symbols/symbols";
import { SectionCellType } from "../../constants/sectionCellType";
import { Status } from "../../enums/Status";
import { getBadgeColorByStatus } from "../Badge/utils/getBadgeColorByStatus";
import Image from "../Image";
import Badge from "../Badge";
import { SectionCellProps } from "./interfaces/SectionCellProps";
import { ContentDirection } from "./constants/ContentDirection";
import { classNames } from "../../utils/classNames";

const SectionCell = ({
  type = SectionCellType.Text,
  title,
  value,
  isLoading,
  element,
  linkTo,
  direction = ContentDirection.Vertical,
  className,
}: SectionCellProps) => {
  const renderContent = () => {
    switch (type) {
      case SectionCellType.Element:
        return element;
      case SectionCellType.HTML:
        return <div dangerouslySetInnerHTML={{ __html: value as string }} />;
      case SectionCellType.Link:
        return (
          <Link to={linkTo as string} className="text-indigo-600 hover:text-indigo-900">
            {value}
          </Link>
        );
      case SectionCellType.Badge:
        return <Badge color={getBadgeColorByStatus(value as Status)} label={(value as Status) || LongDash} />;
      case SectionCellType.List:
        return Array.isArray(value) && !isEmpty(value)
          ? value.map((el: string | number, index) => (
              <div key={`${el}${index}`} className="mt-1 text-sm text-gray-900">
                {el}
              </div>
            ))
          : LongDash;
      case SectionCellType.Image:
        return typeof value === "string" ? (
          <div className="border">
            <Image src={value} size="150px" cursorPointer maxWidth />
          </div>
        ) : null;
      case SectionCellType.Bool:
        return value ? "Yes" : "No";
      default:
        return value || LongDash;
    }
  };

  return (
    <div className={className || (direction === ContentDirection.Horizontal ? "flex pl-4" : "sm:col-span-1")}>
      <dt className="text-sm font-medium text-gray-900">{title}</dt>
      <dd
        className={classNames(
          direction === ContentDirection.Horizontal ? "ml-2" : "mt-1",
          "text-sm text-gray-700 break-words whitespace-pre-wrap"
        )}
      >
        {isLoading ? <Skeleton height={14} className="mt-1" /> : renderContent()}
      </dd>
    </div>
  );
};

export default SectionCell;
