import React, { PropsWithChildren } from "react";
import { classNames } from "../../../utils/classNames";
import { SlidePanelContentProps } from "../interfaces/SlidePanelContent";

export const SlidePanelContentFooter = ({
  className = "",
  children,
}: PropsWithChildren<SlidePanelContentProps>): JSX.Element => (
  <div className={classNames(className, "flex-shrink-0 px-4 py-4 flex justify-end")}>{children}</div>
);
