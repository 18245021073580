import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CustomerPaymentMethod } from "@coasy/csyt-client-library/lib/entities/customer";
import { SlidePanelContentHeader } from "../../../../../common/components/BaseSlidePanel/components/SlidePanelContentHeader";
import { SlidePanelContentMain } from "../../../../../common/components/BaseSlidePanel/components/SlidePanelContentMain";
import { SlidePanelContentFooter } from "../../../../../common/components/BaseSlidePanel/components/SlidePanelContentFooter";
import { SlidePanelFormContent } from "../../../../../common/components/BaseSlidePanel/components/SlidePanelFormContent";
import Button from "../../../../../common/components/Button";
import SubmitButton from "../../../../../common/components/Button/components/SubmitButton";
import { useAppSelector } from "../../../../../common/hooks/useAppSelector";
import UnsavedDataDialog from "../../../../../common/components/UnsavedDataDialog";
import { closeDialog, openDialog, selectDialogVisibility } from "../../../../../common/redux/dialogsSlice";
import { UNSAVED_DATA_DIALOG_NAME } from "../../../constants/DialogNames";
import { useAppDispatch } from "../../../../../common/hooks/useAppDispatch";
import { PaymentMethodSelectedCard } from "../../../../../common/components/PaymentMethod/PaymentMethodSelectedCard";
import ActionsDropdown from "../../../../../common/components/ActionsDropdown";
import { AppRoutes } from "../../../../../common/enums/AppRoutes";

interface PaymentRetryFormProps {
  onSubmit: (id: string) => void;
  onCancel: () => void;
  isLoading: boolean;
  paymentMethods: CustomerPaymentMethod[];
}

export const PaymentRetryForm = ({ onSubmit, onCancel, isLoading, paymentMethods }: PaymentRetryFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation(["actions", "payments", "paymentMethods"]);
  const unsavedDataDialogVisibility: boolean = useAppSelector(selectDialogVisibility(UNSAVED_DATA_DIALOG_NAME));
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  const { invoiceId, dueDate } = useParams<{ invoiceId?: string; dueDate?: string; }>();

  const {
    handleSubmit,
  } = useForm<any>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {},
  });

  const handleOpenUnsavedDataDialog = useCallback(() => {
    dispatch(openDialog(UNSAVED_DATA_DIALOG_NAME));
  }, [dispatch]);

  const handleCloseUnsavedDataDialog = useCallback(() => {
    dispatch(closeDialog(UNSAVED_DATA_DIALOG_NAME));
  }, [dispatch]);

  const handleOnCancel = useCallback(
    () => (selectedPlan ? handleOpenUnsavedDataDialog() : onCancel()),
    [handleOpenUnsavedDataDialog, onCancel, selectedPlan]
  );

  const onApplyUnsavedDataDialog = useCallback(() => {
    handleCloseUnsavedDataDialog();
    onCancel();
  }, [handleCloseUnsavedDataDialog, onCancel]);

  const onFormSubmit = () => {
    if (selectedPlan) {
      onSubmit(selectedPlan);
    }
  };

  const onChangeSelectedPlan = (id: string, selected: boolean) => {
    setSelectedPlan(selected ? id : "");
  };

  return (
    <SlidePanelFormContent onSubmit={handleSubmit(onFormSubmit)}>
      <UnsavedDataDialog
        isOpen={unsavedDataDialogVisibility}
        onCancel={handleCloseUnsavedDataDialog}
        onApply={onApplyUnsavedDataDialog}
      />
      <SlidePanelContentHeader title={t("payments:payment_retry")} handleOnClose={handleOnCancel} />
      <SlidePanelContentMain className="pb-6">
        <div>{t("payments:choose_payment_method")}</div>
        {map(paymentMethods, (method) => (
          <PaymentMethodSelectedCard
            key={method.id}
            id={method.id}
            type={method.type}
            name={method.name}
            onChange={onChangeSelectedPlan}
            isSelected={method.id === selectedPlan}
          />
        ))}
        <ActionsDropdown
          items={[
            {
              name: t("paymentMethods:stripe_direct_debit"),
              action: () => history.push(AppRoutes.PAYMENT_RETRY_DEBIT.replace(":invoiceId?", invoiceId || "").replace(":dueDate?", dueDate || "")),
            },
            {
              name: t("paymentMethods:stripe_credit_card"),
              action: () => history.push(AppRoutes.PAYMENT_RETRY_CREDIT.replace(":invoiceId?", invoiceId || "").replace(":dueDate?", dueDate || "")),
            },
            {
              name: t("paymentMethods:braintree_paypal"),
              action: () => history.push(AppRoutes.PAYMENT_RETRY_PAYPAL.replace(":invoiceId?", invoiceId || "").replace(":dueDate?", dueDate || "")),
            },
          ]}
          label={t("payments:add_payment_method")}
          isLoading={isLoading}
        />
      </SlidePanelContentMain>
      <SlidePanelContentFooter className="space-x-3">
        <Button handleClick={handleOnCancel} label={t("actions:cancel")} />
        <SubmitButton inProgress={isLoading} disabled={!selectedPlan}>
          {t("payments:pay")}
        </SubmitButton>
      </SlidePanelContentFooter>
    </SlidePanelFormContent>
  );
};
