import React, { PropsWithChildren } from "react";
import { classNames } from "../../../utils/classNames";
import { SlidePanelContentProps } from "../interfaces/SlidePanelContent";

export const SlidePanelFormContent = ({ className = "", onSubmit, children }: PropsWithChildren<SlidePanelContentProps>) => (
  <form
    onSubmit={onSubmit}
    className={classNames(className, "h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl")}
  >
    {children}
  </form>
);
