import React, { useEffect, useState } from "react";
import Select from "react-select";
import { OptionDefinition } from "../../../interfaces/OptionDefinition";
import ErrorField from "../../ErrorField";

interface BaseSelectProps {
  id: string;
  label: string;
  initialValue?: OptionDefinition | OptionDefinition[];
  multiple: boolean;
  required?: boolean;
  options: OptionDefinition[];
  error?: string;
  disabled?: boolean;
  clearable?: boolean;
  onChange: (value: OptionDefinition | any | null) => void;
}

const BaseSelect = ({
  id,
  label,
  initialValue,
  multiple,
  required,
  options,
  error,
  disabled,
  clearable,
  onChange,
}: BaseSelectProps) => {
  const [value, setValue] = useState<OptionDefinition | OptionDefinition[] | undefined | null>(initialValue);

  useEffect(() => {
    setValue(!initialValue ? null : initialValue);
  }, [initialValue, label]);

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium">
        {label}
      </label>
      <Select
        name={id}
        inputId={id}
        openMenuOnFocus
        className="my-1"
        styles={{
          input: (base) => ({
            ...base,
            "input:focus": {
              boxShadow: "none",
            },
          }),
          menu: (base) => ({ ...base, zIndex: 1000 }),
        }}
        isMulti={multiple}
        closeMenuOnSelect={!multiple}
        maxMenuHeight={130}
        value={value}
        onChange={onChange}
        options={options}
        isDisabled={disabled}
        isClearable={clearable}
      />
      {error && <ErrorField value={error} />}
    </div>
  );
};

export default BaseSelect;
