import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../Spinner";
import { setHttpClient } from "../../config/auth/useAxios";
import i18n from "../../../i18n";

interface InitialLoadingWrapperProps {
  children: React.ReactNode;
}

const renderLoading = () => (
  <div className="h-screen flex items-center justify-center">
    <Spinner color="border-purple-500" size="128px" />
  </div>
);

const InitialLoadingWrapper = ({ children }: InitialLoadingWrapperProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { ready } = useTranslation([
    "actions",
    "table",
    "translation",
    "userSettings",
    "validation",
    "address",
    "invoices",
    "orders",
    "paymentMethods",
    "table",
  ]);

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  useEffect(() => {
    setHttpClient(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  return ready ? <>{children}</> : renderLoading();
};

export default withAuthenticationRequired(InitialLoadingWrapper);
