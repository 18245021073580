import React from "react";
import { TFunction } from "i18next";
import { Invoice, InvoiceItem } from "@coasy/csyt-client-library/lib/entities/invoice";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { SectionCellType } from "../../../common/constants/sectionCellType";
import { convertNumberToCurrency } from "../../../common/utils/convertNumberToCurrency";
import EntityCardItem from "../../../common/components/EntityList/EntityCardItem";
import { AppRoutes } from "../../../common/enums/AppRoutes";
import { classNames } from "../../../common/utils/classNames";
import EntityItem from "../../../common/components/EntityFullDetails/EntityItem";
import { getHTMLAddressFromContact } from "../../../common/utils/getAddressFromContact";
import SectionCell from "../../../common/components/SectionCell";
import { ContentDirection } from "../../../common/components/SectionCell/constants/ContentDirection";

export const getTotalNetValueDivided = (totalNetMicros: number | undefined): string =>
  totalNetMicros ? convertNumberToCurrency(totalNetMicros / 100) : "0";

export const getEntityDetails = (t: TFunction, invoice?: Invoice, isLoading?: boolean): JSX.Element => (
  <SectionCell
    isLoading={isLoading}
    title={t("invoices:address")}
    value={getHTMLAddressFromContact(invoice?.customer)}
    type={SectionCellType.HTML}
  />
);

export const getSummary = (t: TFunction, invoice?: Invoice, isLoading?: boolean): JSX.Element => (
  <SectionCell
    isLoading={isLoading}
    title={t("translation:total")}
    value={getTotalNetValueDivided(invoice?.totalGrossMicros)}
    direction={ContentDirection.Horizontal}
    className="flex flex-row justify-between"
  />
);

export const getInvoiceEntityItem = (t: TFunction, isLoading?: boolean, item?: InvoiceItem, index?: number): JSX.Element => (
  <EntityItem
    isLoading={isLoading}
    key={`${item?.orderItemId}-${index}`}
    getInfoSection={() =>
      isLoading ? (
        <Skeleton height={15} />
      ) : (
        <h4 className="font-medium text-gray-900 mb-5">{`${item?.number} ${item?.description}`}</h4>
      )
    }
    getInfoPropsSection={() => (
      <>
        <SectionCell
          isLoading={isLoading}
          title={t("quantity")}
          value={item?.quantity}
          direction={ContentDirection.Horizontal}
        />
        <SectionCell
          isLoading={isLoading}
          title={t("price")}
          value={convertNumberToCurrency((item?.singlePriceGrossMicros || 0) / 100)}
          direction={ContentDirection.Horizontal}
        />
      </>
    )}
    getInfoPropsRightSection={() => (
      <SectionCell
        isLoading={isLoading}
        title={t("invoices:total_amount")}
        value={convertNumberToCurrency(((item?.singlePriceGrossMicros || 0) * (item?.quantity || 0)) / 100)}
        direction={ContentDirection.Horizontal}
      />
    )}
  />
);

export const getInvoiceCardItem = (isLoading?: boolean, item?: InvoiceItem, index?: number): JSX.Element => (
  <EntityCardItem
    key={`${item?.orderItemId}-${index}`}
    isLoading={isLoading}
    item={{
      id: item?.orderItemId,
      mainSection: (
        <>
          <div className="font-medium text-gray-900 sm:flex sm:justify-between">
            {isLoading ? (
              <>
                <Skeleton height={20} width={150} />
                <Skeleton height={20} width={50} />
              </>
            ) : (
              <>
                <h5>{item?.number}</h5>
                <p className="mt-2 sm:mt-0">
                  {convertNumberToCurrency(((item?.singlePriceGrossMicros || 0) * (item!.quantity || 0)) / 100)}
                </p>
              </>
            )}
          </div>
          {isLoading ? (
            <>
              <Skeleton height={15} className="mt-5" />
              <Skeleton height={15} />
            </>
          ) : (
            <>
              <p className="hidden text-gray-500 sm:block sm:mt-2 pb-2">{item?.description}</p>
              <p className="hidden text-gray-500 sm:block sm:mt-2 pb-2">
                {item?.quantity} {item?.unit}
              </p>
            </>
          )}
        </>
      ),
    }}
  />
);

export const renderMobileNavigation = (item: Invoice, active: boolean, t: TFunction): JSX.Element => (
  <Link
    to={AppRoutes.INVOICES.replace(":invoiceId", item.invoiceId)}
    className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}
  >
    {t("actions:view")}
  </Link>
);
