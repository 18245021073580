import React from "react";
import Skeleton from "react-loading-skeleton";
import { EntityCardItemProps } from "./interfaces/EntityCardItemProps";

const EntityCardItem = ({ item, isLoading, entityItemProps }: EntityCardItemProps) => (
  <div className="p-4 sm:p-6">
    <div className="flex items-center sm:items-start">
      {entityItemProps?.withImage && (
        <div className="mr-6">
          {isLoading ? (
            <Skeleton height={150} width={150} />
          ) : (
            <div className="flex-shrink-0 w-20 h-20 bg-gray-100 rounded-lg overflow-hidden sm:w-40 sm:h-40">
              <img src={item.imageUrl} alt={item.imageAlt} className="w-full h-full object-center object-contain" />
            </div>
          )}
        </div>
      )}
      <div className="flex-1 text-sm">{item?.mainSection}</div>
    </div>

    {(item?.footerSection || item?.actions) && (
      <div className="mt-6 sm:flex sm:justify-between">
        <div className="flex items-center">{item?.footerSection}</div>

        {!!item?.actions && (
          <div className="mt-6 border-t border-gray-200 pt-4 flex items-center space-x-4 divide-x divide-gray-200 text-sm font-medium sm:mt-0 sm:ml-4 sm:border-none sm:pt-0">
            <div className="flex-1 flex justify-center">{item?.actions}</div>
          </div>
        )}
      </div>
    )}
  </div>
);

export default EntityCardItem;
