import { TFunction } from "i18next";
import { Tab } from "../../../Tabs/interfaces/Tab";
import { PaymentMethods } from "../enums/PaymentMethods";

export const getPaymentMethodTabs = (translator: TFunction): Tab<PaymentMethods>[] => [
  {
    label: translator("stripe_direct_debit"),
    name: PaymentMethods.STRIPE_DIRECT_DEBIT,
  },
  {
    label: translator("stripe_credit_card"),
    name: PaymentMethods.STRIPE_CREDIT_CARD,
  },
  {
    label: translator("braintree_paypal"),
    name: PaymentMethods.BRAINTREE_PAYPAL,
  },
];
