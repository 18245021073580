import { isNumber, isString } from "lodash";

type ConvertFormat = "currency" | "number";

export const convertNumberToCurrency = (value: any, currencySymbol?: string): string => {
  if (currencySymbol === undefined) {
    currencySymbol = " €";
  }
  return value.toFixed(2).toString().replace(".", ",") + currencySymbol;
};

export const convertCurrencyToNumber = (value: string) => (isString(value) ? +value.replace(",", ".") : value);

export const convertNumberToPercents = (value: number): string => `${value / 100} %`;

export const convertArrayFieldTo = <T>(field: keyof T, values: T[] | undefined, format: ConvertFormat) =>
  values?.map((value) => {
    const fieldValue = value[field];

    if (format === "currency" && isNumber(fieldValue)) {
      const newValue: any = convertNumberToCurrency(fieldValue / 100, "");
      value[field] = newValue;
    }

    if (format === "number" && isString(fieldValue)) {
      const newValue: any = convertCurrencyToNumber(fieldValue) * 100;
      value[field] = newValue;
    }

    return value;
  }) || [];
