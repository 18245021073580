import React from "react";
import DocumentDownloadIcon from "@heroicons/react/solid/DocumentDownloadIcon";
import { useTranslation } from "react-i18next";
import Button from "../index";
import { BaseButtonProps } from "../interfaces/BaseButtonProps";

const DownloadButton = ({ handleClick, label, isLoading, disabled, className }: BaseButtonProps) => {
  const { t } = useTranslation(["actions"]);

  return (
    <Button handleClick={handleClick} label={label} isLoading={isLoading} disabled={disabled} className={className}>
      <>
        <DocumentDownloadIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
        {label || t("actions:download")}
      </>
    </Button>
  );
};

export default DownloadButton;
