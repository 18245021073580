import { ButtonVariant } from "../constants/buttonVariant";

export const getButtonStyles = (variant: ButtonVariant) => {
  switch (variant) {
    case ButtonVariant.Main:
      return "inline-flex items-center justify-center px-4 py-2 border text-base sm:text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 border-transparent text-white bg-indigo-600 hover:bg-indigo-700";
    case ButtonVariant.Icon:
      return "bg-transparent rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white";
    case ButtonVariant.Text:
      return "text-indigo-600 hover:text-indigo-90";
    case ButtonVariant.Action:
      return "inline-flex items-center justify-center px-4 py-2 border text-base sm:text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 border-transparent text-white bg-gray-800 hover:bg-gray-900";
    default:
      return "inline-flex items-center justify-center px-4 py-2 border text-base sm:text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 border-gray-300 shadow-sm text-gray-700 bg-white hover:bg-gray-50";
  }
};
