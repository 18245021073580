import { Dispatch } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { PaymentMethodType } from "@coasy/csyt-client-library/lib/types/payment-method-type";
import { PaymentMethods } from "../enums/PaymentMethods";
import { validateBraintreePayPal } from "./validateBraintreePayPal";
import { openNotification, setNotificationMessage, setNotificationType } from "../../../../redux/notificationsSlice";
import { actions, PaymentMethodCreateRequiredData } from "../../../../redux/paymentMethodsSlice";
import { validateStripeDirectDebit } from "./validateStripeDirectDebit";
import { validateStripeCreditCard } from "./validateStripeCreditCard";
import { CreatingPaymentMethodData } from "../../../../../services/interfaces/CreatingPaymentMethodData";

export const submitPaymentMethod = async (
  data: any,
  t: TFunction,
  tokenizePayload: string,
  createRequiredData: PaymentMethodCreateRequiredData,
  acceptedAgreement: boolean,
  currentTab: PaymentMethodType,
  dispatch: Dispatch<any>,
  setSkippedAcceptedAgreement: (value: boolean) => void,
  stripe: Stripe | null,
  elements: StripeElements | null,
  onSubmit: (data: CreatingPaymentMethodData) => void,
  deviceData?: string,
  ): Promise<any> => {
  if (!acceptedAgreement && currentTab === PaymentMethods.STRIPE_DIRECT_DEBIT) {
    setSkippedAcceptedAgreement(true);
  } else {
    dispatch(actions.setIsLoading(true));
    try {
      let braintreeData: any;
      if (currentTab === PaymentMethods.STRIPE_DIRECT_DEBIT) {
        const { bankAccountOwner } = data;
        await validateStripeDirectDebit(bankAccountOwner, stripe, createRequiredData, elements);
      } else if (currentTab === PaymentMethods.STRIPE_CREDIT_CARD) {
        const { creditCardOwner } = data;
        await validateStripeCreditCard(creditCardOwner, stripe, createRequiredData, elements);
      } else {
        validateBraintreePayPal(tokenizePayload, t);
        braintreeData = {
          ...createRequiredData.clientCredentials.braintreeClientCredentials?.braintreeData,
          tokenizePayload,
          deviceData,
        };
      }

      onSubmit({
        setupIntentId: createRequiredData.clientCredentials.stripeClientCredentials?.setupIntentId,
        paymentMethod: currentTab,
        braintreeData,
      });
    } catch (err: any) {
      dispatch(setNotificationMessage(err.message));
      dispatch(setNotificationType("error"));
      dispatch(openNotification());
      dispatch(actions.setIsLoading(false));
    }
  }
};
