import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

interface UseAxios {
  get: <T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig | undefined) => Promise<R>;
  post: <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig | undefined) => Promise<R>;
  put: <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig | undefined) => Promise<R>;
  destroy: <T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig | undefined) => Promise<R>;
  patch: <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig | undefined) => Promise<R>;
}

export const useAxios = (): UseAxios => {
  const { getAccessTokenSilently } = useAuth0();
  const instance = axios.create();

  instance.interceptors.request.use(
    async (config) => {
      const token = await getAccessTokenSilently();
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  const { get, post, put, delete: destroy, patch } = instance;
  return { get, post, put, destroy, patch };
};

const httpClient: AxiosInstance = axios.create();

export function setHttpClient(getAccessTokenSilently: any): void {
  httpClient.interceptors.request.use(
    async (config) => {
      const token = await getAccessTokenSilently();
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
}

export const appHttpService = httpClient;
