import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Invoice } from "@coasy/csyt-client-library/lib/entities/invoice";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import Layout from "../../common/components/Layout";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import { useAppSelector } from "../../common/hooks/useAppSelector";

import invoiceApiService from "../../services/InvoiceService";
import { actions, selectors, thunks } from "../../common/redux/invoicesSlice";
import { BreadcrumbsPath } from "../../common/utils/BreadcrumbsPath";
import { AppRoutes } from "../../common/enums/AppRoutes";
import { InvoiceView } from "./components/InvoiceView";
import { InvoiceDetails } from "../../common/interfaces/InvoiceDetails";
import { getInvoiceCardItem, getTotalNetValueDivided, renderMobileNavigation } from "./constants/getInvoiceCardConfig";
import EntityListLayout from "../../common/components/EntityList/EntityListLayout";
import { ListWithSkeletonLayout } from "../../common/components/ListWithSkeletonLayout";
import EntityCard from "../../common/components/EntityList/EntityCard";
import ViewButton from "../../common/components/Button/components/ViewButton";

const InvoicesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { invoiceId } = useParams<{ invoiceId?: string }>();
  const isShowView = invoiceId;
  const history = useHistory();
  const { t } = useTranslation(["invoices", "actions"]);

  const invoices: Invoice[] = useAppSelector(selectors.selectAll);
  const selectedInvoice: InvoiceDetails = useSelector(selectors.selectSelectedEntity);
  const isLoading: boolean = useAppSelector(selectors.selectIsLoading);

  useEffect(() => {
    if (invoiceId) {
      dispatch(thunks.setSelectedEntity(invoiceApiService.findById, invoiceId));
    } else {
      dispatch(actions.clearSelectedEntity());
    }
  }, [invoiceId, dispatch]);

  useEffect(() => {
    dispatch(thunks.fetchTableData(invoiceApiService.findAll));
  }, [dispatch]);

  const getBreadcrumbsPath = useCallback((): BreadcrumbsPath | undefined => {
    const mainPath = new BreadcrumbsPath(t("invoices"), AppRoutes.INVOICES_LIST);
    if (isShowView) {
      const viewPath = new BreadcrumbsPath(invoiceId || "");
      mainPath.setChildren(viewPath);
      return mainPath;
    }
  }, [isShowView, invoiceId, t]);

  const handleViewInvoice = useCallback(
    (invoiceId: string) => {
      const invoiceViewPath = AppRoutes.INVOICES.replace(":invoiceId", invoiceId);
      history.push(invoiceViewPath);
    },
    [history]
  );

  const handleViewInvoicePlans = useCallback(
    (invoiceId: string) => {
      const paymentsViewPath = AppRoutes.INVOICE_PAYMENTS_VIEW.replace(":invoiceId?", invoiceId);
      history.push(paymentsViewPath);
    },
    [history]
  );

  const getItemComponent = useCallback(
    (isLoading: boolean, item?: Invoice, index?: number) => (
      <EntityCard
        key={`${item?.invoiceId}-${index}`}
        isLoading={isLoading}
        headerData={[
          {
            title: t("invoices:number"),
            value: item?.number,
          },
          {
            title: t("invoices:date"),
            value: item?.timestamp ? format(new Date(item?.timestamp as number), "dd.MM.yyyy") : undefined,
          },
          {
            title: t("invoices:total_amount"),
            value: getTotalNetValueDivided(item?.totalGrossMicros),
          },
        ]}
        itemsData={() => (
          <ListWithSkeletonLayout isLoading={isLoading!} items={item?.invoiceItems} getItemComponent={getInvoiceCardItem} />
        )}
        navigationElements={
          <>
            <ViewButton handleClick={() => handleViewInvoice(item!.invoiceId)} isLoading={isLoading} />
            <ViewButton
              handleClick={() => handleViewInvoicePlans(item!.invoiceId)}
              isLoading={isLoading}
              label={t("invoices:view_payment_plans")}
            />
          </>
        }
        mobileNavigationTitle={t("invoices:invoice_options")}
        renderMobileNavigation={(active: boolean) => renderMobileNavigation(item!, active, t)}
      />
    ),
    [handleViewInvoice, handleViewInvoicePlans, t]
  );

  return (
    <Layout title="Invoices | Customer App" breadcrumbsPath={getBreadcrumbsPath()}>
      {isShowView ? (
        <InvoiceView invoice={selectedInvoice} isLoading={isLoading} handleViewInvoicePlans={handleViewInvoicePlans} />
      ) : (
        <EntityListLayout title={t("invoices")} subTitle={t("invoices:invoices_subtitle")}>
          <ListWithSkeletonLayout<Invoice> isLoading={isLoading} items={invoices} getItemComponent={getItemComponent} />
        </EntityListLayout>
      )}
    </Layout>
  );
};

export default InvoicesPage;
