import { TFunction } from "i18next";
import { Dispatch } from "@reduxjs/toolkit";
import { Payment } from "@coasy/csyt-client-library/lib/entities";
import { Invoice } from "@coasy/csyt-client-library/lib/entities/invoice";
import paymentApiService from "../../services/PaymentService";
import invoiceApiService from "../../services/InvoiceService";
import { createEntitySlice } from "./helpers/createEntitySlice";
import { setNotificationMessage, setNotificationType, openNotification } from "./notificationsSlice";

export interface PaymentMethodCreateRequiredData {
  selectedPayment: Payment;
  invoicePayments: Payment[];
  invoices: Invoice[];
}

interface InitialState {
  createRequiredData?: PaymentMethodCreateRequiredData;
  isCreateRequiredDataLoading: boolean;
}

const initialState: InitialState = {
  createRequiredData: undefined,
  isCreateRequiredDataLoading: false,
};

export const { actions, reducer, selectors, thunks } = createEntitySlice<Payment>(
  "payments",
  (payment) => `${payment.invoiceId}/${payment.dueDate}`,
  initialState,
  {
    setCreateRequiredData: (state: any, action: any) => {
      if (state.createRequiredData) {
        state.createRequiredData = { ...state.createRequiredData, ...action.payload };
      } else {
        state.createRequiredData = action.payload;
      }
    },
    setIsCreateRequiredDataLoading: (state: any, action: any) => {
      state.isCreateRequiredDataLoading = action.payload;
    },
  },
  (entitySlice: any) => ({
    fetchInvoices: () => async (dispatch: Dispatch<any>, getState: any) => {
      try {
        const { payments } = getState();
        if (payments?.createRequiredData?.invoices) {
          return;
        }
        dispatch(entitySlice.actions.setIsCreateRequiredDataLoading(true));
        const invoice = await invoiceApiService.findAll();
        dispatch(entitySlice.actions.setCreateRequiredData({ invoices: invoice?.invoices }));
      } catch (err) {
        console.error(err);
        dispatch(setNotificationMessage("Error fetching invoices"));
        dispatch(setNotificationType("error"));
        dispatch(openNotification());
      } finally {
        dispatch(entitySlice.actions.setIsCreateRequiredDataLoading(false));
      }
    },
    fetchInvoicePayments: (invoiceId: string) => async (dispatch: Dispatch<any>, getState: any) => {
      try {
        dispatch(entitySlice.actions.setIsCreateRequiredDataLoading(true));
        const { payments } = getState();
        if (payments?.createRequiredData?.invoicePayments) {
          return;
        }
        const invoicePayments = await paymentApiService.findById(invoiceId);
        dispatch(entitySlice.actions.setCreateRequiredData({ invoicePayments: invoicePayments.payments }));
      } catch (err) {
        console.error(err);
        dispatch(setNotificationMessage("Error fetching the payments data"));
        dispatch(setNotificationType("error"));
        dispatch(openNotification());
      } finally {
        dispatch(entitySlice.actions.setIsCreateRequiredDataLoading(false));
      }
    },
    fetchSelectedPayment: (invoiceId: string, dueDate: string) => async (dispatch: Dispatch<any>) => {
      try {
        dispatch(entitySlice.actions.setIsCreateRequiredDataLoading(true));
        const payment = await paymentApiService.findByIdAndDate(invoiceId, dueDate);
        dispatch(entitySlice.actions.setCreateRequiredData({ selectedPayment: payment.payments?.[0] }));
      } catch (err) {
        console.error(err);
        dispatch(setNotificationMessage("Error fetching the payment"));
        dispatch(setNotificationType("error"));
        dispatch(openNotification());
      } finally {
        dispatch(entitySlice.actions.setIsCreateRequiredDataLoading(false));
      }
    },
    paymentRetry: (
      invoiceId: string,
      dueDate: string,
      id: string,
      t: TFunction,
      successCb: () => void
    ) => async (dispatch: Dispatch<any>) => {
      try {
        dispatch(entitySlice.actions.setIsLoading(true));
        await paymentApiService.retry(invoiceId, dueDate, { paymentMethodId: id });
        successCb();
        dispatch(setNotificationMessage(t("retry_success")));
        dispatch(setNotificationType("success"));
        dispatch(openNotification());
      } catch (err) {
        console.error(err);
        dispatch(setNotificationMessage(t("retry_error")));
        dispatch(setNotificationType("error"));
        dispatch(openNotification());
      } finally {
        dispatch(entitySlice.actions.setIsLoading(false));
      }
    },
  }),
  {
    selectCreateRequiredData: (state: any) => state.payments.createRequiredData,
    selectIsCreateRequiredDataLoading: (state: any) => state.payments.isCreateRequiredDataLoading,
  }
);

export default reducer;
