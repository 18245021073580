import { Stripe, StripeElements, StripeIbanElement } from "@stripe/stripe-js";
import { IbanElement } from "@stripe/react-stripe-js";
import { PaymentMethodCreateRequiredData } from "../../../../redux/paymentMethodsSlice";
import { throwStripeErrorIfRequired } from "./throwStripeErrorIfRequired";

export const validateStripeDirectDebit = async (
  bankAccountOwner: string,
  stripe: Stripe | null,
  createRequiredData: PaymentMethodCreateRequiredData,
  elements: StripeElements | null
): Promise<any> => {
  const response = await stripe?.confirmSepaDebitSetup(
    createRequiredData.clientCredentials.stripeClientCredentials?.clientSecret || "",
    {
      payment_method: {
        sepa_debit: elements?.getElement(IbanElement) as StripeIbanElement,
        billing_details: {
          name: bankAccountOwner,
          email: createRequiredData.clientCredentials.email,
        },
      },
    }
  );
  throwStripeErrorIfRequired(response?.error);
};
