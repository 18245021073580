import { ContactData } from "@coasy/csyt-client-library/lib/entities";

export const getHTMLAddressFromContact = (contact?: ContactData) => {
  const company = `${contact?.company ? `${contact.company} <br/>` : ""}`.trim();
  const customer = `${contact?.firstName || ""} ${contact?.lastName || ""}${contact?.firstName || contact?.lastName ? "<br/>" : ""}`.trim();
  const street = `${contact?.street ? `${contact.street} <br/>` : ""}`.trim();
  const street2 = `${contact?.street2 ? `${contact.street2} <br/>` : ""}`.trim();
  const city = `${contact?.countryCode ? `${contact?.countryCode} - ` : ""}${contact?.zipCode || ""} ${contact?.city}`.trim();
  const address = company + customer + street + street2 + city
    .replace(/\s+/g, " ")
    .trim();
  return address;
}

export const getAddressFromContact = (contact?: ContactData) =>
  `${contact?.company || ""} ${contact?.firstName || ""} ${contact?.lastName || ""} ${contact?.street2 || ""} ${contact?.street || ""
    }`
    .replace(/\s+/g, " ")
    .trim();