import React from "react";
import { Link, useLocation } from "react-router-dom";
import { BreadcrumbsPath } from "../../../utils/BreadcrumbsPath";

const getCustomBreadcrumbs = (path: BreadcrumbsPath, isFirst = true): any => {
  const children = path.getChildren();
  return (
    <>
      {getItem(path.getLabel(), path?.getPath() || "", !children, isFirst)}
      {children && getCustomBreadcrumbs(children, false)}
    </>
  );
};

const getDefaultBreadcrumbs = (pathnames: string[]) =>
  pathnames.filter(Boolean).map((path, index, array) => {
    const value = path;
    const to = `/${array.slice(0, index + 1).join("/")}`;
    const last = index === array.length - 1;
    return getItem(value, to, last, !index);
  });

const getItem = (value: string, to: string, isLast: boolean, isFirst: boolean) => (
  <li key={to} className="flex">
    <div className="flex items-center">
      {!isFirst && (
        <svg
          className="flex-shrink-0 w-6 h-full text-gray-200"
          viewBox="0 0 24 44"
          preserveAspectRatio="none"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
        </svg>
      )}
      {isLast ? (
        <span className="ml-4 text-sm font-medium text-gray-400">{value}</span>
      ) : (
        <Link to={to} className="ml-4 text-sm font-medium text-gray-600 hover:text-gray-500">
          {value}
        </Link>
      )}
    </div>
  </li>
);

interface BreadcrumpsProps {
  path?: BreadcrumbsPath;
}

const Breadcrumps: React.FC<BreadcrumpsProps> = ({ path }) => {
  const location = useLocation();
  return (
    <ol className="hidden sm:flex space-x-4 px-2 sm:px-4 lg:px-8 h-11">
      {/* <li className="flex">
        <div className="flex items-center">
          <Link to={AppRoutes?.DASHBOARD} className="text-gray-400 hover:text-gray-300">
            <HomeIcon className="flex-shrink-0 h-5 w-5" />
          </Link>
        </div>
      </li> */}
      {path ? getCustomBreadcrumbs(path) : getDefaultBreadcrumbs(location.pathname.split("/"))}
    </ol>
  );
};

export default Breadcrumps;
