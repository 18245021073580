import React, { PropsWithChildren } from "react";
import { ButtonTypes } from "./constants/buttonTypes";
import { BaseButtonProps } from "./interfaces/BaseButtonProps";
import { SkeletonButton } from "./components/SkeletonButton";
import Spinner from "../Spinner";
import { getButtonStyles } from "./utils/getButtonStyles";
import { ButtonVariant } from "./constants/buttonVariant";

const Button = ({
  handleClick,
  type = ButtonTypes.Button,
  variant = ButtonVariant.Secondary,
  label = "",
  isLoading,
  inProgress,
  disabled,
  className,
  children,
}: PropsWithChildren<BaseButtonProps> & { type?: ButtonTypes }) => (
  <div className="mt-6 md:mt-0 button-wrapper">
    {isLoading ? (
      <SkeletonButton />
    ) : (
      <button
        type={type}
        className={`${getButtonStyles(variant)} ${className || ""}`}
        onClick={handleClick}
        disabled={disabled || isLoading || inProgress}
      >
        {inProgress && (
          <div className="mr-1">
            <Spinner color={variant === ButtonVariant.Main ? "border-white" : "border-gray-700"} size="11px" />
          </div>
        )}
        {children || label}
      </button>
    )}
  </div>
);

export default Button;
