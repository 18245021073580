export class BreadcrumbsPath {
  private readonly label: string;

  private readonly path?: string;

  private children?: BreadcrumbsPath;

  constructor(label: string, path?: string) {
    this.label = label;
    this.path = path;
  }

  public setChildren(children: BreadcrumbsPath) {
    this.children = children;
  }

  public getChildren(): BreadcrumbsPath | undefined {
    return this.children;
  }

  public getLabel(): string {
    return this.label;
  }

  public getPath(): string | undefined {
    return this.path;
  }
}
