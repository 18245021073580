import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../controls/Checkbox";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import {
  openNotification,
  setNotificationMessage,
  setNotificationType,
} from "../../../../redux/notificationsSlice";
import { PaymentMethodCreateRequiredData } from "../../../../redux/paymentMethodsSlice";
import paymentMethodApiService from "../../../../../services/PaymentMethodService";
import PayPalButton from "./PayPalButton";

const PaypalForm = ({
  tokenizePayload,
  setTokenizePayload,
  setDeviceData,
  createRequiredData,
}: {
  tokenizePayload: any;
  setTokenizePayload: any;
  setDeviceData: any;
  createRequiredData: PaymentMethodCreateRequiredData;
}): JSX.Element => {
  const { t } = useTranslation(["paymentMethods", "actions"]);
  const dispatch = useAppDispatch();

  const onPayPalUpdate = async (tokenizePayload?: any, deviceData?: any) => {
    const isAccountConnected = await paymentMethodApiService.isBillingAgreementIdConnected(
      tokenizePayload?.details?.billingAgreementId
    );

    if (isAccountConnected) {
      dispatch(setNotificationMessage(t("paypal_account_is_already_connected")));
      dispatch(setNotificationType("error"));
      dispatch(openNotification());
    } else {
      setTokenizePayload(tokenizePayload);
      setDeviceData(deviceData);
    }
  };

  return (
    <>
      {tokenizePayload ? (
        <Checkbox
          id="connected_to_pay_pal_account_checkbox"
          label={`${t("connected_to_pay_pal_account")} ${tokenizePayload.details.email}`}
          value
          onChange={() => {
            setTokenizePayload(undefined);
            setDeviceData(undefined);
          }}
        />
      ) : (
        <>
          <p>{t("connect_to_pay_pal_account")}</p>
          <PayPalButton
            braintreeClientToken={
              createRequiredData.clientCredentials.braintreeClientCredentials?.braintreeData?.clientToken
            }
            onUpdate={onPayPalUpdate}
          />
        </>
      )}
    </>
  );
};

export { PaypalForm };
