import React from "react";

interface ISpinnerProps {
  color: string;
  size?: string;
}

const Spinner = ({ color, size = "16px" }: ISpinnerProps) => (
  <div className="flex justify-center items-center">
    <div className={`animate-spin rounded-full mr-1 border-b-2 ${color}`} style={{ width: size, height: size }} />
  </div>
);

export default Spinner;
