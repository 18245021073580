const tenants = {
  PBAA: {
    company: "Peter Beer GmbH",
  },
  CBP: {
    company: "Christian Bischoff LIFE! GmbH",
  },
};

const tenantKey = process.env.REACT_APP_PUBLIC_TENANT || "PBAA";

const tenant = tenants[tenantKey as keyof typeof tenants];

export { tenant };
