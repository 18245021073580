export const enum SectionCellType {
  Text = "text",
  Element = "element",
  Badge = "badge",
  List = "list",
  Link = "link",
  Image = "img",
  Bool = "bool",
  HTML = "html",
}
