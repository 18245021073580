import { ContactData } from "@coasy/csyt-client-library/lib/entities";
import { appHttpService } from "../common/config/auth/useAxios";

interface AddressApiService {
    getBillingAddress: (params?: any) => Promise<BillingAddressResponse>;
    getDeliveryAddress: (params?: any) => Promise<DeliveryAddressResponse>;
    updateBillingAddress: (dto: ContactData) => Promise<BillingAddressResponse>;
    updateDeliveryAddress: (dto: ContactData) => Promise<DeliveryAddressResponse>;
}

export interface BillingAddressResponse {
  message: string;
  billingAddress: ContactData;
  total: number;
}

export interface DeliveryAddressResponse {
  message: string;
  deliveryAddress: ContactData;
  total: number;
}

const billingAddressEndpoint = `${process.env.REACT_APP_CUSTOMER_ENDPOINT}/billingAddress`;
const deliveryAddressEndpoint = `${process.env.REACT_APP_CUSTOMER_ENDPOINT}/deliveryAddress`;

const addressApiService: AddressApiService = {
    getBillingAddress: (params?: any): Promise<BillingAddressResponse> =>
        appHttpService.get<Promise<BillingAddressResponse>>(billingAddressEndpoint, { params }).then((response) => response.data),
    getDeliveryAddress: (params?: any): Promise<DeliveryAddressResponse> =>
        appHttpService.get<Promise<DeliveryAddressResponse>>(deliveryAddressEndpoint, { params }).then((response) => response.data),
    updateBillingAddress: (dto: ContactData): Promise<BillingAddressResponse> =>
        appHttpService.put(billingAddressEndpoint, dto).then(response => response.data),
    updateDeliveryAddress: (dto: ContactData): Promise<DeliveryAddressResponse> =>
        appHttpService.put(deliveryAddressEndpoint, dto).then(response => response.data)
};

export default addressApiService;
