export enum AppRoutes {
  OVERVIEW = "/",
  MY_ADDRESS = "/myAddress",
  ORDERS = "/orders/:actionId?/:orderId?",
  ORDERS_LIST = "/orders",
  ORDER_VIEW = "/orders/view/:orderId?",
  INVOICES = "/invoices/:invoiceId?",
  INVOICES_LIST = "/invoices",
  SUBSCRIPTIONS = "/subscriptions",
  SETTINGS = "/settings",
  SUPPORT = "/support",
  INVOICE_VIEW = "/invoices/view/:invoiceId?",
  PAYMENT_METHOD = "/paymentMethods/:actionId?/:id?",
  PAYMENT_METHOD_LIST = "/paymentMethods",
  PAYMENT_METHOD_CREATE = "/paymentMethods/create",
  PAYMENTS = "/payments/:actionId?/:invoiceId?/:dueDate?/:typeId?",
  PAYMENTS_LIST = "/payments",
  INVOICE_PAYMENTS_VIEW = "/payments/view/:invoiceId?",
  PAYMENT_VIEW = "/payments/view/:invoiceId?/:dueDate?",
  PAYMENT_RETRY = "/payments/retry/:invoiceId?/:dueDate?",
  PAYMENT_RETRY_DEBIT = "/payments/retry/:invoiceId?/:dueDate?/debit",
  PAYMENT_RETRY_CREDIT = "/payments/retry/:invoiceId?/:dueDate?/credit",
  PAYMENT_RETRY_PAYPAL = "/payments/retry/:invoiceId?/:dueDate?/paypal",
}
