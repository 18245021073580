import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { SwitcherProps } from "./interfaces/SwitcherProps";
import { classNames } from "../../../utils/classNames";

export const Switcher = forwardRef<HTMLButtonElement, SwitcherProps>(({ label, value = false, disabled, onChange }, ref) => {
  const [enabled, setEnabled] = useState<boolean>(value);

  useEffect(() => {
    setEnabled(!!value);
  }, [value]);

  const updateValue = useCallback(
    (checked: boolean) => {
      setEnabled(checked);
      if (onChange) {
        onChange(checked);
      }
    },
    [onChange]
  );

  return (
    <div className="flex items-center">
      <Switch
        checked={enabled}
        disabled={disabled}
        onChange={updateValue}
        className={classNames(
          enabled ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
          "disabled:opacity-50"
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
      <label className="ml-3 text-sm font-medium">{label}</label>
    </div>
  );
});
