import { Order } from "@coasy/csyt-client-library/lib/entities";
import { appHttpService } from "../common/config/auth/useAxios";
import { EntityList } from "../common/interfaces/EntityList/EntityList";

interface OrderApiService {
  findAll: (params?: any) => Promise<EntityList<Order, "orders">>;
  findById: (orderId: string) => Promise<EntityList<Order, "orders">>;
}

const orderEndpoint = `${process.env.REACT_APP_CUSTOMER_ENDPOINT}/orders`;

const orderApiService: OrderApiService = {
  findAll: (params?: any): Promise<EntityList<Order, "orders">> =>
    appHttpService.get<Promise<EntityList<Order, "orders">>>(orderEndpoint, { params }).then((response) => response.data),
  findById: (orderId: string): Promise<EntityList<Order, "orders">> =>
    appHttpService
      .get<Promise<EntityList<Order, "orders">>>(`${orderEndpoint}/${orderId}`)
      .then((response) => response.data),
};

export default orderApiService;
