import { sortBy } from "lodash";
import { Customer, User } from "@coasy/csyt-client-library/lib/entities";
import { Invitation } from "@coasy/csyt-client-library/lib/entities/invitation";
import { Invoice } from "@coasy/csyt-client-library/lib/entities/invoice";
import { SortColumn } from "../components/EntityDataTable";

export enum TableComparators {
  CUSTOMER_ID = "customer_id",
  INVITATION_NAME = "invitation_name",
  INVOICE_CUSTOMER = "invoice_customer",
  USER_NAME = "user_name",
  USER_ROLE = "user_role",
}

export const comparators = {
  [TableComparators.CUSTOMER_ID]: (data: Customer) =>
    `${data?.firstName || ""} ${data?.lastName || ""} ${data?.company || ""}`,
  [TableComparators.INVITATION_NAME]: (data: Invitation) => `${data?.firstName || ""} ${data?.lastName || ""}`,
  [TableComparators.INVOICE_CUSTOMER]: (data: Invoice) =>
    `${data?.customer?.firstName || ""} ${data?.customer?.lastName || ""} ${data?.customer?.email}`,
  [TableComparators.USER_NAME]: (data: User) => `${data?.firstName || ""} ${data?.lastName || ""}`,
  [TableComparators.USER_ROLE]: (data: User) => data?.roles?.map((role) => role)?.join(" "),
};

export const sortTableBySortColumn = (arr: any[], sortColumn?: SortColumn) => {
  if (sortColumn) {
    const { id, orderBy, comparator } = sortColumn;
    return orderBy === "asc"
      ? sortBy(arr, comparators[comparator as TableComparators] || [id])
      : sortBy(arr, comparators[comparator as TableComparators] || [id]).reverse();
  }
  return arr;
};
