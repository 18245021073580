import { Order, OrderItem } from "@coasy/csyt-client-library/lib/entities/order";
import React from "react";
import { TFunction } from "i18next";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { SectionCellType } from "../../../common/constants/sectionCellType";
import { convertNumberToCurrency } from "../../../common/utils/convertNumberToCurrency";
import { buildS3ImagePath } from "../../../common/utils/buildS3ImagePath";
import SectionCell from "../../../common/components/SectionCell";
import EntityCardItem from "../../../common/components/EntityList/EntityCardItem";
import { AppRoutes } from "../../../common/enums/AppRoutes";
import { classNames } from "../../../common/utils/classNames";

export const getTotalNetValueDivided = (totalNetMicros: number | undefined): string =>
  totalNetMicros ? convertNumberToCurrency(totalNetMicros / 100) : "0 €";

export const getOrderCardItem = (isLoading?: boolean, item?: OrderItem, index?: number): JSX.Element => {
  const paymentPlan = !isLoading
    ? item?.offer?.paymentPlans?.find((plan) => plan.id === item?.selectedPaymentPlan)
    : undefined;

  return (
    <EntityCardItem
      key={`${item?.orderItemId}-${index}`}
      entityItemProps={{ withImage: true }}
      isLoading={isLoading}
      item={{
        id: item?.orderItemId,
        imageUrl: buildS3ImagePath(item?.offer?.image?.s3Key || ""),
        imageAlt: item?.offer?.image?.fileName || "",
        mainSection: (
          <>
            <div className="font-medium text-gray-900 sm:flex sm:justify-between">
              {isLoading ? (
                <>
                  <Skeleton height={20} width={150} />
                  <Skeleton height={20} width={50} />
                </>
              ) : (
                <>
                  <h5>{item?.offer?.title}</h5>
                  <div className="flex flex-col items-center">
                    <p className="mt-2 mb-1 sm:mt-0">{paymentPlan?.description || ""}</p>
                    <SectionCell isLoading={isLoading} title="" value={item?.status} type={SectionCellType.Badge} />
                  </div>
                </>
              )}
            </div>
          </>
        ),
      }}
    />
  );
};

export const renderMobileNavigation = (item: Order, active: boolean, t: TFunction) => (
  <Link
    to={AppRoutes.ORDERS.replace(":orderId", item.orderId)}
    className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}
  >
    {t("actions:view")}
  </Link>
);
