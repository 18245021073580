import { Status } from "../../../enums/Status";
import { BadgeTypeColor } from "../constants/BadgeTypeColor";

export const getBadgeColorByStatus = (status: Status | "ACTIVE" | "BLOCKED" | "PENDING"): BadgeTypeColor => {
  switch (status) {
    case Status.ESCALATE:
      return BadgeTypeColor.Red;
    case Status.ACTIVE:
      return BadgeTypeColor.Blue;
    case Status.DONE:
    case Status.PAID:
      return BadgeTypeColor.Green;
    case Status.PENDING:
    default:
      return BadgeTypeColor.Yellow;
  }
};
