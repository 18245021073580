import React from "react";
import Skeleton from "react-loading-skeleton";
import { EntityFullDetailsProps } from "./interfaces/EntityFullDetailsProps";

const EntityFullDetails = ({
  additionalTitle,
  title,
  subtitle,
  actions,
  additionalContent,
  isLoading,
  getEntityItems,
  getEntityDetails,
  getSummary,
}: EntityFullDetailsProps) => (
  <div className="bg-white mx-8 mb-8 rounded-xl">
    <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
      <div className="flex flex-col justify-between sm:flex-row">
        <div>
          {!!additionalTitle && (
            <h1 className="text-sm font-semibold uppercase tracking-wide text-indigo-600">{additionalTitle}</h1>
          )}
          {isLoading ? (
            <Skeleton height={20} width={250} className="mt-2" />
          ) : (
            <p className="mt-2 text-2xl font-extrabold tracking-tight sm:text-2xl">{title}</p>
          )}
          {!!subtitle && <p className="mt-2 text-base text-gray-500">{subtitle}</p>}
        </div>
        {actions && <div>{actions}</div>}
      </div>

      <div className="mt-10 border-t border-gray-200">
        <h3 className="sr-only">Items</h3>

        {getEntityItems && getEntityItems()}

        <div className="sm:ml-40 sm:pl-6">
          <dl className="grid grid-cols-2 gap-x-6 text-sm py-4 divide-y divide-gray-200 border-b border-gray-200">
            {getEntityDetails && getEntityDetails()}
          </dl>

          <dl className="space-y-6 text-sm pt-10">{getSummary && getSummary()}</dl>
        </div>
      </div>
      {additionalContent &&
        (isLoading ? (
          <Skeleton height={15} />
        ) : (
          <div className="mt-10 border-t border-gray-200 space-y-6 text-sm pt-10">{additionalContent}</div>
        ))}
    </div>
  </div>
);

export default EntityFullDetails;
