import { OptionDefinition } from "../interfaces/OptionDefinition";
import { EnumLike } from "../interfaces/EnumLike";

const UNDERSCORE = "_";
const SPACE = " ";

type EntityConfig<T> = {
  label: keyof T;
  valueResolver?: keyof T;
};

export const getOptionsByEntities = <T>(entities: T[], config: EntityConfig<T>): OptionDefinition[] =>
  // @ts-ignore
  entities.map((entity) => ({
    label: `${entity[config.label]}`,
    value: config.valueResolver ? entity[config.valueResolver] : entity,
  }));

export const getOptionsByEnum = <T extends EnumLike<T>>(en: T) => {
  const options: OptionDefinition[] = [];
  for (const record in en) {
    options.push({ label: getLabel(record), value: en[record] });
  }
  return options;
};

const getOptions = (values: string[], long?: boolean): OptionDefinition[] =>
  long ? values.map((value) => getOption(value, long)) : values.map((value) => getOption(value));

const getOption = (value: string, long?: boolean) => ({
  label: long ? getMultipleLabel(value) : getLabel(value),
  value,
});

const getLabel = (value: string) => value.charAt(0) + value.slice(1).toLowerCase().replace(UNDERSCORE, SPACE);

// const getStatusOptions = (statuses: Status[]): OptionDefinition[] => statuses.map(getOption);

export const getMultipleLabel = (value: string) =>
  value
    ?.split(UNDERSCORE)
    ?.map((word) => getLabel(word))
    .join(SPACE);

export { getOptions, getOption, getLabel };
