import React from "react";

import {
  BriefcaseIcon,
  CalendarIcon,
  CashIcon,
  CogIcon,
  CollectionIcon,
  CreditCardIcon,
  HomeIcon,
  IdentificationIcon,
  PhoneMissedCallIcon,
} from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../enums/AppRoutes";
import { NavigationElement } from "./interface/NavigationElement";

const navigation: NavigationElement[] = [
  { name: "overview", to: AppRoutes.OVERVIEW, icon: HomeIcon },
  { name: "my_address", to: AppRoutes.MY_ADDRESS, icon: IdentificationIcon },
  { name: "orders", to: AppRoutes.ORDERS_LIST, icon: BriefcaseIcon },
  { name: "invoices", to: AppRoutes.INVOICES_LIST, icon: CalendarIcon },
  { name: "payments", to: AppRoutes.PAYMENTS_LIST, icon: CreditCardIcon },
  { name: "subscriptions", to: AppRoutes.SUBSCRIPTIONS, icon: CollectionIcon },
  { name: "payment_methods", to: AppRoutes.PAYMENT_METHOD_LIST, icon: CashIcon },
  { name: "settings", to: AppRoutes.SETTINGS, icon: CogIcon },
  { name: "support", to: AppRoutes.SUPPORT, icon: PhoneMissedCallIcon },
];

const NavigationMenu = () => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <nav className="space-y-1">
      {navigation.map((item) => {
        const isActive = location.pathname === item.to || (location.pathname.includes(item.to) && item.to !== "/");
        return (
          <Link
            key={item.name}
            to={item.to}
            className={`${isActive ? "bg-gray-200 text-gray-900" : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"}
              
              flex items-center px-3 py-2 text-sm font-medium rounded-md`}
          >
            <item.icon className={`${isActive ? "text-gray-500" : "text-gray-400"}, flex-shrink-0 -ml-1 mr-3 h-6 w-6`} />
            <span className="truncate">{t(item.name)}</span>
          </Link>
        );
      })}
    </nav>
  );
};

export default NavigationMenu;
