import React from "react";

interface CheckboxProps {
  id: string;
  label: string;
  value: boolean;
  className?: string;
  labelClassName?: string;
  onChange: (checked: boolean) => void;
}

const Checkbox = ({ id, label, value, className, labelClassName, onChange }: CheckboxProps) => (
  <div className={`${className || ""} flex items-center`}>
    <input
      id={id}
      checked={value}
      type="checkbox"
      onChange={(e) => onChange(e.target.checked)}
      className="sm:text-sm cursor-pointer my-1 mr-2 rounded focus:ring-indigo-500  text-indigo-600 border-gray-300"
    />
    <label htmlFor={id} className={labelClassName}>
      {label}
    </label>
  </div>
);

export { Checkbox };
