import React from "react";
import { useTranslation } from "react-i18next";
import BaseDialog from "../BaseDialog";

interface UnsavedDataDialogProps {
  isOpen: boolean;
  onApply: () => void;
  onCancel: () => void;
}

const UnsavedDataDialog: React.FC<UnsavedDataDialogProps> = ({ isOpen, onApply, onCancel }) => {
  const { t } = useTranslation("actions");
  return isOpen ? (
    <BaseDialog
      type="warn"
      title={t("unsaved_data_confirmation")}
      content={t("you_have_unsaved_data")}
      onApply={onApply}
      onCancel={onCancel}
    />
  ) : null;
};

export default UnsavedDataDialog;
