import React from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Payment } from "@coasy/csyt-client-library/lib/entities";
import { Invoice } from "@coasy/csyt-client-library/lib/entities/invoice";
import EntityFullDetails from "../../../../common/components/EntityFullDetails";
import { ListWithSkeletonLayout } from "../../../../common/components/ListWithSkeletonLayout";
import { getEntityDetails, PaymentCardItem, getSummary } from "../../constants/getPaymentCardConfig";

const PaymentView = ({ payment, isLoading, invoices }: { payment?: Payment; isLoading: boolean, invoices?:Invoice[] }): JSX.Element => {
  const { t } = useTranslation("payments");

  return (
    <EntityFullDetails
      isLoading={isLoading}
      additionalTitle={t("translation:thank_you")}
      title={`${t("payment")} - ${payment?.dueDate ? format(new Date(payment!.dueDate), "dd.MM.yyyy") : ""}`}
      subtitle={`${t("payments:invoice_number")} - ${invoices?.find((invoice) => invoice.invoiceId === payment?.invoiceId)?.number}`}
      getEntityItems={() => (
        <ListWithSkeletonLayout
          isLoading={isLoading!}
          items={payment?.items}
          getItemComponent={PaymentCardItem}
        />
      )}
      getEntityDetails={() => getEntityDetails(t, payment, isLoading, invoices)}
      getSummary={() => getSummary(t, payment, isLoading)}
    />
  );
};

export default PaymentView;
